/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EyeExamination } from '../models/EyeExamination';
import type { EyeExaminationPartial } from '../models/EyeExaminationPartial';
import type { EyeExaminationWithRelations } from '../models/EyeExaminationWithRelations';
import type { NewEyeExamination } from '../models/NewEyeExamination';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EyeExaminationControllerService {
  /**
   * @returns any EyeExamination model count
   * @throws ApiError
   */
  public static eyeExaminationControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/eye-examinations/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any EyeExamination  success
   * @throws ApiError
   */
  public static eyeExaminationControllerDashboardEyeExamination(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/eye-examinations/dashboardEyeExamination',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static eyeExaminationControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: EyeExamination;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/eye-examinations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static eyeExaminationControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: EyeExaminationPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/eye-examinations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EyeExaminationWithRelations EyeExamination model instance
   * @throws ApiError
   */
  public static eyeExaminationControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<EyeExaminationWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/eye-examinations/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static eyeExaminationControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/eye-examinations/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns EyeExamination EyeExamination model instance
   * @throws ApiError
   */
  public static eyeExaminationControllerCreate({
    requestBody,
  }: {
    requestBody?: NewEyeExamination;
  }): CancelablePromise<EyeExamination> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/eye-examinations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any EyeExamination PATCH success count
   * @throws ApiError
   */
  public static eyeExaminationControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: EyeExaminationPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/eye-examinations',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EyeExaminationWithRelations Array of EyeExamination model instances
   * @throws ApiError
   */
  public static eyeExaminationControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<EyeExaminationWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/eye-examinations',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
