/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BmiWithRelations } from '../models/BmiWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BmiControllerService {
  /**
   * @returns BmiWithRelations Bmi model instance
   * @throws ApiError
   */
  public static bmiControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BmiWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bmi/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any Array of Bmi model instances
   * @throws ApiError
   */
  public static bmiControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<BmiWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bmi',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
