/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Frequency } from '../models/Frequency';
import type { NewFrequency } from '../models/NewFrequency';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FrequencyControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static frequencyControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/frequency/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Frequency Frequency model instance
   * @throws ApiError
   */
  public static frequencyControllerCreate({
    requestBody,
  }: {
    requestBody?: NewFrequency;
  }): CancelablePromise<Frequency> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/frequency',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Frequency Array of Frequency model instances
   * @throws ApiError
   */
  public static frequencyControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<Frequency>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/frequency',
      query: {
        filter: filter,
      },
    });
  }
}
