import { apiWrapper } from 'api';
import { ChatControllerService, ChatWithRelations } from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { readStoreChatMessage } from 'redux/chat/actions';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { handleError } from 'utils/handleError';
import { returnUpdatedList } from 'utils/helpers';
import useLoading from 'utils/useLoading/useLoading';
import usePaginationWrapper from 'utils/usePaginationWrapper/usePaginationWrapper';

interface IProps {
  chatRoomId?: string;
  reverse?: boolean;
}

const useGetChats = ({ chatRoomId, reverse = false }: IProps) => {
  const dispatch = useDispatch();
  const coach = useAppSelector((state) => state.user?.currentUser);
  const handle = useLoading();
  const [chats, setChats] = useState<(ChatWithRelations & { frontendId?: string })[]>([]);

  const onAddNewChatCallback = useCallback((data) => setChats((prev) => [data, ...prev]), []);

  const onIncomingChat = useCallback(
    (data) => {
      if (data.senderId === coach?.id) {
        const chat = chats.find((chat) => chat.frontendId === data.frontendId);
        if (chat) {
          const newChat = { ...chat, ...data };
          delete newChat.frontendId;
          const index = chats.findIndex((item) => item.frontendId === data.frontendId);
          chats[index] = newChat;
          setChats([...chats]);
        }
      } else {
        setChats((prev) => [data, ...prev]);
      }
    },
    [chats, coach?.id],
  );

  const { setPageable, limit, page, totalElements, totalPages, setPage } = usePaginationWrapper();

  const getChats = async (page: number, chatRoomId: string, callback?: () => void) => {
    handle.startLoading();
    if (page === 1) {
      setChats([]);
    }
    try {
      const res = await apiWrapper(() =>
        ChatControllerService.chatControllerFind({
          filter: {
            where: {
              chatRoomId,
            },
            limit,
          },
          page,
          reverse,
        }),
      );
      //   @ts-ignore
      const chatData = res?.data;
      setPageable(res);
      if (res?.currentPage === 1) {
        void setChats([...chatData]);
      } else {
        void setChats((prev) => [...prev, ...chatData]);
      }
      callback?.();
      handle.stopLoading();
      return chatData || [];
    } catch (error) {
      handleError(error);
      handle.stopLoading();
    }
  };

  useEffect(() => {
    if (page && chatRoomId) {
      getChats(page, chatRoomId);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId, page]);

  const onReadMessage = (chat: ChatWithRelations) => {
    setChats((prev) => {
      const newList = returnUpdatedList(chat, prev, 'id');
      return newList;
    });
    dispatch(readStoreChatMessage(chat));
  };

  const isInitialLoading = handle.loading && chats.length === 0;

  return {
    chats,
    setChats,
    loading: handle.loading,
    isInitialLoading,
    onAddNewChatCallback,
    totalElements,
    totalPages,
    setPage,
    page,
    onReadMessage,
    onIncomingChat,
  };
};

export default useGetChats;
