import { useQueryClient } from '@tanstack/react-query';
import { useGetRequest } from 'api/useGetRequest';
import { ChatRoom, ChatRoomControllerService, UserSubscriptionsWithRelations } from 'generated';

interface IProps {
  id?: string;
  userId?: string;
  callback?: () => void;
}

export interface ChatRoomSummaryResponse {
  chatRoom?: ChatRoom;
  resources?: Array<string>;
  users?: Array<{
    id?: string;
    name?: string;
    image?: string;
    phone?: string;
    email?: string;
    subscriptions?: UserSubscriptionsWithRelations[];
  }>;
  coach?: {
    id?: string;
    name?: string;
    image?: string;
  };
}

const useGetChatRoomSummary = ({ id, userId, callback }: IProps) => {
  const queryClient = useQueryClient();
  const refetch = () => {
    queryClient.invalidateQueries(['chatRoomControllerGetSummary']);
  };
  const { data, loading } = useGetRequest({
    service: ChatRoomControllerService.chatRoomControllerGetSummary,
    tag: 'chatRoomControllerGetSummary',
    payload: {
      id,
      userId,
    },
    enabled: id?.length > 0,
    onSuccess: () => {
      callback?.();
    },
  });

  return {
    loadingSummary: loading,
    chatRoomSummary: data,
    refetchSummary: refetch,
  };
};

export default useGetChatRoomSummary;
