import ModalComponent from 'components/Modal/Modal';
import { ChatRoomWithRelations, Users } from 'generated';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useChatSocketContext } from 'utils/chatSocketProvider/ChatSocketProvider';
import ChatArea from '../components/ChatArea/ChatArea';
import useChatActions from '../hooks/useChatActions';
import useGetActiveChatRoom from '../hooks/useGetActiveChatRoom';
import useGetChats from '../hooks/useGetChats';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  chatRoom?: ChatRoomWithRelations;
  member?: Users;
}

const ActiveChatModal = ({ isOpen, onClose, chatRoom, member }: Props) => {
  const coach = useAppSelector((state) => state.user?.currentUser);
  const { readChatMessage, sendNewchat, activeChatRoom, setActiveChatRoom, createChatRoom } =
    useChatSocketContext();

  const { data, loading } = useGetActiveChatRoom({
    receiverCoachId: coach?.id,
    receiverMemberId: member?.id,
  });

  const createRoom = (userId: string) => {
    const chatroom: any = {
      creatorId: coach?.id,
      receiverCoachId: coach?.id,
      receiverIds: [userId],
      type: 'direct',
      creatorType: 'coach',
      receiverMemberId: userId,
    };
    createChatRoom(chatroom);
  };

  useEffect(() => {
    if (chatRoom && !activeChatRoom) {
      setActiveChatRoom(chatRoom);
      return;
    } else if (data?.chatRoom) {
      setActiveChatRoom(data.chatRoom);

      return;
    } else if (!chatRoom && !activeChatRoom && member && isOpen) {
      createRoom(member.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom, member, activeChatRoom, isOpen, data]);

  const {
    chats,
    onAddNewChatCallback,
    onIncomingChat,
    loading: loadingChats,
    isInitialLoading,
    page,
    setPage,
    totalPages,
    totalElements,
    onReadMessage,
  } = useGetChats({
    chatRoomId: activeChatRoom?.id,
  });

  useChatActions({
    onIncomingMessage: onIncomingChat,
    onReadMessage,
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setActiveChatRoom(undefined);
      }}
      title="Chat"
      size="lg"
    >
      <ChatArea
        sendNewchat={(chat) => {
          onAddNewChatCallback(chat);
          sendNewchat(chat);
        }}
        activeChatRoom={activeChatRoom}
        chats={chats}
        isInitialLoading={isInitialLoading}
        readChatMessage={readChatMessage}
        loading={loadingChats || loading}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalElements={totalElements}
      />
    </ModalComponent>
  );
};

export default ActiveChatModal;
