import { useEffect, useState } from 'react';

interface IProps {
  defaultActiveIndex?: number;
}

const useTabsComponent = ({ defaultActiveIndex = 0 }: IProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex || 0);

  useEffect(() => {
    setActiveIndex(defaultActiveIndex);
  }, [defaultActiveIndex]);

  return {
    defaultActiveIndex,
    setActiveIndex,
    activeIndex,
  };
};

export default useTabsComponent;
