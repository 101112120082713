import { useMutateRequest } from 'api/useMutation';
import { NotificationControllerService } from 'generated';

export const useMarkNotificationAsRead = () => {
  const { isLoading, trigger } = useMutateRequest({
    service: NotificationControllerService.notificationControllerUpdateById,
    tag: 'NotificationControllerService',
  });

  return {
    isLoading,
    trigger,
  };
};
