import { CombinedState, combineReducers, Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import chatReducer, { IAppChatState } from './chat/reducer';
import notificationReducer, { INotificationAppState } from './notification/reducer';
import userReducer, { IAppUserState } from './user/reducer';

const rootReducer: Reducer<
  CombinedState<{
    user: IAppUserState & PersistPartial;
    chat: IAppChatState & PersistPartial;
    notification: INotificationAppState & PersistPartial;
  }>
> = combineReducers({
  user: userReducer,
  chat: chatReducer,
  notification: notificationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
