/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dependents } from '../models/Dependents';
import type { HubVisit } from '../models/HubVisit';
import type { HubVisitPartialExcluding_id_ } from '../models/HubVisitPartialExcluding_id_';
import type { HubVisitWithRelations } from '../models/HubVisitWithRelations';
import type { NewDependents } from '../models/NewDependents';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HubVisitControllerService {
  /**
   * @returns any Statistics for a NudgeHub hubVisit
   * @throws ApiError
   */
  public static hubVisitControllerGetStatistics({
    nudgeHubId,
    startDate,
    endDate,
  }: {
    nudgeHubId: string;
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<{
    totalCheckedIn?: number;
    totalCheckedOut?: number;
    male?: number;
    female?: number;
    totalDependents?: number;
    totalPayments?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hub-visit/statistics/{nudgeHubId}',
      path: {
        nudgeHubId: nudgeHubId,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static hubVisitControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HubVisitPartialExcluding_id_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/hub-visit/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HubVisitWithRelations HubVisit model instance
   * @throws ApiError
   */
  public static hubVisitControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<HubVisitWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hub-visit/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns HubVisit HubVisit model instance
   * @throws ApiError
   */
  public static hubVisitControllerCreate({
    requestBody,
  }: {
    requestBody?: {
      userId?: string;
      nudgeHubId?: string;
      actionPlanId?: string;
      createdById?: string;
      userFullName?: string;
      userPhoneNumber?: string;
      userEmail?: string;
      userDigiFirstId?: string;
      checkInTime?: string;
      checkOutTime?: string;
      hasCheckedOut?: boolean;
      hasDependent?: boolean;
      dependentList?: Array<string>;
      needConsult?: boolean;
      hasFollowUp?: boolean;
      isFollowUpVisit?: boolean;
      followUpDate?: string;
      reasonForVisit?: Array<string>;
      reasonForVisitOther?: string;
      soughtHealthInfoOn?: Array<string>;
      soughtHealthInfoOtherTopics?: string;
      soughtHealthInfoOtherIllnesses?: string;
      visitOutcome?: Array<string>;
      diagnosis?: Array<string>;
      diagnosisOther?: string;
      visitConducted?: 'inside_the_hub' | 'outside_of_the_hub';
      channel?: 'web' | 'mobile';
      created_at?: string;
      modified_at?: string;
      dependents?: Array<NewDependents>;
    };
  }): CancelablePromise<HubVisit> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/hub-visit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of HubVisit model instances
   * @throws ApiError
   */
  public static hubVisitControllerFindAll({
    filter,
    page,
    search,
    startDate,
    endDate,
  }: {
    filter?: any;
    page?: number;
    search?: string;
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<HubVisitWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hub-visit',
      query: {
        filter: filter,
        page: page,
        search: search,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static hubVisitControllerUpdateDependentById({
    requestBody,
  }: {
    requestBody?: Array<Dependents>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/hub-visit-dependent',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static hubVisitControllerDeleteDependentByIds({
    dependentIds,
  }: {
    dependentIds?: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/hub-visit-dependent',
      query: {
        dependentIds: dependentIds,
      },
    });
  }

  /**
   * @returns any Array of HubVisit model instances
   * @throws ApiError
   */
  public static hubVisitControllerFindSolinaSummary({
    startDate,
    endDate,
    filter,
  }: {
    startDate?: string;
    endDate?: string;
    filter?: any;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<HubVisitWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hub-visit-solina-summary',
      query: {
        startDate: startDate,
        endDate: endDate,
        filter: filter,
      },
    });
  }
}
