import { FC } from 'react';

type CountdownTimerProps = {
  hour?: number | string;
  min?: number | string;
  sec?: number | string;
};

const CountdownTimer: FC<CountdownTimerProps> = ({ hour, min, sec }) => {
  return (
    <div className="flex gap-3">
      {hour !== undefined && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center rounded border border-gray-200 h-10 w-10">
            <h4 aria-label="Countdown hours" aria-live="polite">
              {hour}
            </h4>
          </div>
          <small>Hour</small>
        </div>
      )}
      {min !== undefined && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center rounded border border-gray-200 h-10 w-10">
            <h4 aria-label="Countdown minutes" aria-live="polite">
              {min}
            </h4>
          </div>
          <small>Min</small>
        </div>
      )}
      {sec !== undefined && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center rounded border border-gray-200 h-10 w-10">
            <h4 aria-label="Countdown seconds" aria-live="polite">
              {sec}
            </h4>
          </div>
          <small>Sec</small>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
