/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicationPassport } from '../models/MedicationPassport';
import type { MedicationPassportPartial } from '../models/MedicationPassportPartial';
import type { MedicationPassportWithRelations } from '../models/MedicationPassportWithRelations';
import type { NewMedicationPassport } from '../models/NewMedicationPassport';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MedicationpassportControllerService {
  /**
   * @returns any MedicationPassport model count
   * @throws ApiError
   */
  public static medicationpassportControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medication-passports/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any MedicationPassport  success
   * @throws ApiError
   */
  public static medicationpassportControllerDashboardMedicationPassport(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medication-passports/dashboardMedicationPassport',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationpassportControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: MedicationPassport;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/medication-passports/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationpassportControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: MedicationPassportPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/medication-passports/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MedicationPassportWithRelations MedicationPassport model instance
   * @throws ApiError
   */
  public static medicationpassportControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<MedicationPassportWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medication-passports/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationpassportControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/medication-passports/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns MedicationPassport MedicationPassport model instance
   * @throws ApiError
   */
  public static medicationpassportControllerCreate({
    requestBody,
  }: {
    requestBody?: NewMedicationPassport;
  }): CancelablePromise<MedicationPassport> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/medication-passports',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any MedicationPassport PATCH success count
   * @throws ApiError
   */
  public static medicationpassportControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: MedicationPassportPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/medication-passports',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MedicationPassportWithRelations Array of MedicationPassport model instances
   * @throws ApiError
   */
  public static medicationpassportControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<MedicationPassportWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medication-passports',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any MedicationPassport  success
   * @throws ApiError
   */
  public static medicationpassportControllerMedicationPassportsMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medicationPassportsMigration',
    });
  }
}
