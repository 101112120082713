/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FootExamination } from '../models/FootExamination';
import type { FootExaminationPartial } from '../models/FootExaminationPartial';
import type { FootExaminationWithRelations } from '../models/FootExaminationWithRelations';
import type { NewFootExamination } from '../models/NewFootExamination';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FootExaminationControllerService {
  /**
   * @returns any FootExamination model count
   * @throws ApiError
   */
  public static footExaminationControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/foot-examinations/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any FootExamination  success
   * @throws ApiError
   */
  public static footExaminationControllerDashboardFootExamination(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/foot-examinations/dashboardFootExamination',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static footExaminationControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: FootExamination;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/foot-examinations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static footExaminationControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: FootExaminationPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/foot-examinations/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FootExaminationWithRelations FootExamination model instance
   * @throws ApiError
   */
  public static footExaminationControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<FootExaminationWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/foot-examinations/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static footExaminationControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/foot-examinations/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns FootExamination FootExamination model instance
   * @throws ApiError
   */
  public static footExaminationControllerCreate({
    requestBody,
  }: {
    requestBody?: NewFootExamination;
  }): CancelablePromise<FootExamination> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/foot-examinations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any FootExamination PATCH success count
   * @throws ApiError
   */
  public static footExaminationControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: FootExaminationPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/foot-examinations',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FootExaminationWithRelations Array of FootExamination model instances
   * @throws ApiError
   */
  public static footExaminationControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<FootExaminationWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/foot-examinations',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
