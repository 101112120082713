import { Chat, ChatRoom, ChatWithRelations } from 'generated';
import { useEffect } from 'react';
import { useChatSocketContext } from 'utils/chatSocketProvider/ChatSocketProvider';

interface IProps {
  onCreateChatRoom?: (chatroom?: ChatRoom) => void;
  onIncomingMessage?: (chat: ChatWithRelations) => void;
  onReadMessage?: (chat: Chat) => void;
}

const useChatActions = ({ onCreateChatRoom, onReadMessage, onIncomingMessage }: IProps) => {
  const {
    incoming,
    setIncoming,
    activeChatRoom,
    incomingChatRoom,
    setIncomingChatRoom,
    incomingReadMessage,
    setIncomingReadMessage,
  } = useChatSocketContext();

  useEffect(() => {
    if (incoming) {
      onIncomingMessage?.(incoming);
      setIncoming(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incoming, activeChatRoom?.id]);

  useEffect(() => {
    if (incomingReadMessage) {
      onReadMessage?.(incomingReadMessage);
      setIncomingReadMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingReadMessage]);

  useEffect(() => {
    if (incomingChatRoom) {
      onCreateChatRoom?.(incomingChatRoom);
      setIncomingChatRoom(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingChatRoom]);
};

export default useChatActions;
