/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPharmacy } from '../models/NewPharmacy';
import type { Pharmacy } from '../models/Pharmacy';
import type { PharmacyPartial } from '../models/PharmacyPartial';
import type { PharmacyWithRelations } from '../models/PharmacyWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PharmacyControllerService {
  /**
   * @returns any Pharmacy model count
   * @throws ApiError
   */
  public static pharmacyControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pharmacies/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any Pharmacy  success
   * @throws ApiError
   */
  public static pharmacyControllerDashboardMyPharmacy(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pharmacies/dashboardMyPharmacy',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static pharmacyControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Pharmacy;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/pharmacies/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static pharmacyControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PharmacyPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/pharmacies/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PharmacyWithRelations Pharmacy model instance
   * @throws ApiError
   */
  public static pharmacyControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PharmacyWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pharmacies/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static pharmacyControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/pharmacies/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Pharmacy Pharmacy model instance
   * @throws ApiError
   */
  public static pharmacyControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPharmacy;
  }): CancelablePromise<Pharmacy> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/pharmacies',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Pharmacy PATCH success count
   * @throws ApiError
   */
  public static pharmacyControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: PharmacyPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/pharmacies',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PharmacyWithRelations Array of Pharmacy model instances
   * @throws ApiError
   */
  public static pharmacyControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<PharmacyWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pharmacies',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any Pharmacy  success
   * @throws ApiError
   */
  public static pharmacyControllerPharmaciesMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pharmaciesMigration',
    });
  }
}
