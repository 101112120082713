interface Props {
  file: string;
  handleImageBlowup?: (file: string) => void;
  width?: string;
  height?: string;
  fileType?: string;
  fileName?: string;
  noBlowUp?: boolean;
  cursor?: string;
}

const CheckFileType = ({
  file,
  handleImageBlowup,
  width,
  height,
  fileName,
  fileType,
  noBlowUp,
  cursor,
}: Props): JSX.Element | null => {
  if (file) {
    const check = fileType ? fileType.split('/')[1] : file.split('.').pop();
    switch (check) {
      case 'pdf':
        return (
          <div className="txt p-4">
            <a href={file} rel="noopener noreferrer" target="_blank" className="flex space-x-1">
              <img
                src="https://res.cloudinary.com/mdoc/image/upload/v1663142811/pdf-svgrepo-com_1_cflqlq.svg"
                alt=""
                width={25}
                height={25}
                className="me-2"
              />
              <span className="truncate w-10/12 text-wrap">{fileName || file.split('/')[4]}</span>
            </a>
          </div>
        );
      case 'mov':
      case 'mp4':
      case 'avi':
      case 'mkv':
      case 'wmv':
      case 'mpeg':
      case 'quicktime':
      case 'webm':
      case 'flv':
        return (
          <div className="txt p-1">
            <video
              controls
              src={file}
              style={{
                width: width === '' ? '300px' : width,
                height: height === '' ? '200px' : height,
                objectFit: 'cover',
              }}
            />
          </div>
        );
      case 'wav':
      case 'mp3':
      case 'aac':
      case 'flac':
      case 'ogg':
      case 'aiff':
      case 'm4a':
      case 'wma':
        return (
          <div className="txt p-1">
            <audio
              controls
              src={file}
              style={{
                width: width === '' ? '300px' : width,
                height: height === '' ? '30px' : height,
                objectFit: noBlowUp ? 'contain' : 'cover',
              }}
            />
          </div>
        );

      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'JPEG':
      case 'png':
      case 'PNG':
        return (
          <div className="txt p-1">
            <img
              src={file}
              style={{
                width: width || '300px',
                height: height || '200px',
                cursor: cursor || 'pointer',
                objectFit: 'cover',
              }}
              className="rounded-lg border border-primary"
              alt="img"
              onClick={handleImageBlowup ? () => handleImageBlowup(file) : () => {}}
            />
          </div>
        );
      default:
        return (
          <div className="txt p-1">
            <img
              src={file}
              style={{
                width: width || '300px',
                height: height || '200px',
                cursor: 'zoom-in',
                objectFit: 'cover',
              }}
              className="rounded-lg border border-primary"
              alt="img"
              onClick={handleImageBlowup ? () => handleImageBlowup(file) : () => {}}
            />
          </div>
        );
    }
  } else {
    return null;
  }
};

export default CheckFileType;
