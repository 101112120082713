/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DigiFirst } from '../models/DigiFirst';
import type { DigiFirstPartialExcluding_id_created_at_ } from '../models/DigiFirstPartialExcluding_id_created_at_';
import type { DigiFirstWithRelations } from '../models/DigiFirstWithRelations';
import type { NewDigiFirst } from '../models/NewDigiFirst';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DigiFirstControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static digiFirstControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: DigiFirstPartialExcluding_id_created_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/digi-first/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DigiFirstWithRelations DigiFirst model instance
   * @throws ApiError
   */
  public static digiFirstControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<DigiFirstWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/digi-first/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static digiFirstControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/digi-first/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns DigiFirst DigiFirst model instance
   * @throws ApiError
   */
  public static digiFirstControllerCreate({
    requestBody,
  }: {
    requestBody?: NewDigiFirst;
  }): CancelablePromise<DigiFirst> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/digi-first',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DigiFirstWithRelations Array of DigiFirst model instances
   * @throws ApiError
   */
  public static digiFirstControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<DigiFirstWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/digi-first',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
