import { Chat } from 'generated';
import { IAppChatState } from './reducer';
import chatActionTypes from './types';

export const updateChatGroupMembersState = (payload) => ({
  type: chatActionTypes.UPDATE_NEW_GROUP_MEMBERS,
  payload,
});

export const addIdToDelete = (payload: string) => ({
  type: chatActionTypes.ADD_ID_TO_DELETE,
  payload,
});

export const logActiveChatRoomId = (payload: string) => ({
  type: chatActionTypes.ACTIVE_CHAT_ROOM_ID,
  payload,
});

export const readStoreChatMessage = (payload: Chat) => ({
  type: chatActionTypes.READ_CHAT,
  payload,
});

export const addUnreadChat = (payload: Chat) => ({
  type: chatActionTypes.ADD_UNREAD_CHAT,
  payload,
});

export const updateAppChatState = (payload: IAppChatState) => ({
  type: chatActionTypes.UPDATE_APP_CHAT_STATE,
  payload,
});
