/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalenderEvent } from '../models/CalenderEvent';
import type { CalenderEventPartial } from '../models/CalenderEventPartial';
import type { CalenderEventWithRelations } from '../models/CalenderEventWithRelations';
import type { NewCalenderEvent } from '../models/NewCalenderEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalenderEventControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static calenderEventControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CalenderEventPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/calender-events/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CalenderEventWithRelations CalenderEvent model instance
   * @throws ApiError
   */
  public static calenderEventControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CalenderEventWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/calender-events/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static calenderEventControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/calender-events/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns CalenderEvent CalenderEvent model instance
   * @throws ApiError
   */
  public static calenderEventControllerCreate({
    requestBody,
  }: {
    requestBody?: NewCalenderEvent;
  }): CancelablePromise<CalenderEvent> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/calender-events',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of CalenderEvent model instances
   * @throws ApiError
   */
  public static calenderEventControllerFind({ filter }: { filter?: any }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/calender-events',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns CalenderEventWithRelations CalenderEvent model instance
   * @throws ApiError
   */
  public static calenderEventControllerFindWebinarById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CalenderEventWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/webinar/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns CalenderEvent BlogPost model instance
   * @throws ApiError
   */
  public static calenderEventControllerCreateWebinar({
    formData,
  }: {
    formData?: {
      title?: string;
      notes?: string;
      registrationLink?: string;
      recordedSessionLink?: string;
      eventDate?: string;
      isPublished?: string;
      isPublicEvent?: string;
    };
  }): CancelablePromise<CalenderEvent> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/webinar',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any CalenderEvent model instance
   * @throws ApiError
   */
  public static calenderEventControllerWebinarStatistics(): CancelablePromise<{
    draftWebinars?: number;
    totalWebinars?: number;
    publishedWebinars?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/webinar-statistics',
    });
  }

  /**
   * @returns any Array of CalenderEvent model instances
   * @throws ApiError
   */
  public static calenderEventControllerFindBySearch({
    page,
    search,
    filter,
  }: {
    page?: number;
    search?: string;
    filter?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/webinars/search',
      query: {
        page: page,
        search: search,
        filter: filter,
      },
    });
  }
}
