import { useMutateRequest } from 'api/useMutation';
import { getApps, initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { CoachControllerService } from 'generated';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { updateAppUserState } from 'redux/user/actions';

//TODO: move this to env
const FIREBASE_APIKEY = 'AIzaSyBfslVnna-ihAndf4speFUTQX4fu9M5QtE';

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: 'mdoc-completehealth-app.firebaseapp.com',
  databaseURL: 'https://mdoc-completehealth-app.firebaseio.com',
  projectId: 'mdoc-completehealth-app',
  storageBucket: 'mdoc-completehealth-app.appspot.com',
  messagingSenderId: '310150579193',
  appId: '1:310150579193:web:a6b8cfb9d98f3513abf328',
  measurementId: 'G-PVHP8KQV5Q',
};

const vapidKey =
  'BEYRIRss3ZZ3-yoBuAzJFMJTGwFTyfpb0PfHC2NUVUw27Og3gmJnJA2mYnUl4R6-8RjksAGwhHbYknV2vN8BloU';

async function requestPermission() {
  console.log('Requesting permission...');
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    return true;
  }
  // @ts-ignore
  return permission === 'granted';
}

const initializeFirebase = () => {
  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }
  const granted = requestPermission();

  return {
    permissionGranted: granted,
  };
};

export const useFCMToken = () => {
  initializeApp(firebaseConfig);
  const messaging = getMessaging();
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user?.currentUser);
  const [generatedToken, setGeneratedToken] = useState<string | undefined>(user?.fcmToken);
  const token = user?.fcmToken;

  const { trigger } = useMutateRequest({
    service: CoachControllerService.coachControllerUpdateById,
    tag: 'usersControllerUpdateById',
    onSuccess: () =>
      dispatch(
        updateAppUserState({
          currentUser: {
            ...user,
            fcmToken: generatedToken,
          },
        }),
      ),
  });

  const getFCMToken = async () => {
    getToken(messaging, { vapidKey: vapidKey })
      .then((newToken) => {
        if (newToken) {
          setGeneratedToken(newToken);
          if (user?.id) {
            trigger({
              id: user?.id || '',
              requestBody: {
                fcmToken: newToken,
              },
            });
          }
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  };

  useEffect(() => {
    (async () => {
      const { permissionGranted } = await initializeFirebase();
      if (permissionGranted) {
        getFCMToken();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
