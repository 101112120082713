import DOMPurify from 'isomorphic-dompurify';

export const purifyHtml = (html: string): string => {
  const htmlString = DOMPurify.sanitize(html);

  return htmlString;
};

export const truncateString = (input: string, maxLength: number): string => {
  if (input.length > maxLength) {
    return input.slice(0, maxLength) + '...';
  }
  return input;
};
