/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthCondition } from '../models/HealthCondition';
import type { HealthConditionPartial } from '../models/HealthConditionPartial';
import type { HealthConditionWithRelations } from '../models/HealthConditionWithRelations';
import type { NewHealthCondition } from '../models/NewHealthCondition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HealthConditionControllerService {
  /**
   * @returns any HealthCondition model count
   * @throws ApiError
   */
  public static healthConditionControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-conditions/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any HealthCondition  success
   * @throws ApiError
   */
  public static healthConditionControllerDashboardHealthCondition(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-conditions/dashboardHealthCondition',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthConditionControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HealthCondition;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/health-conditions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthConditionControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HealthConditionPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/health-conditions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HealthConditionWithRelations HealthCondition model instance
   * @throws ApiError
   */
  public static healthConditionControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<HealthConditionWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-conditions/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthConditionControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/health-conditions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns HealthConditionWithRelations HealthCondition model instance
   * @throws ApiError
   */
  public static healthConditionControllerCreate({
    requestBody,
  }: {
    requestBody?: NewHealthCondition;
  }): CancelablePromise<HealthConditionWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/health-conditions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any HealthCondition PATCH success count
   * @throws ApiError
   */
  public static healthConditionControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: HealthConditionPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/health-conditions',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HealthConditionWithRelations Array of HealthCondition model instances
   * @throws ApiError
   */
  public static healthConditionControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<HealthConditionWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-conditions',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any HealthCondition  success
   * @throws ApiError
   */
  public static healthConditionControllerHealthConditionsMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthConditionsMigration',
    });
  }
}
