/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Exercise } from '../models/Exercise';
import type { ExercisePartial } from '../models/ExercisePartial';
import type { ExerciseWithRelations } from '../models/ExerciseWithRelations';
import type { NewExercise } from '../models/NewExercise';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExerciseControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static exerciseControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ExercisePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/exercise/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ExerciseWithRelations Exercise model instance
   * @throws ApiError
   */
  public static exerciseControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ExerciseWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/exercise/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static exerciseControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/exercise/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Exercise Exercise model instance
   * @throws ApiError
   */
  public static exerciseControllerCreate({
    requestBody,
  }: {
    requestBody?: NewExercise;
  }): CancelablePromise<Exercise> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/exercise',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ExerciseWithRelations Array of Exercise model instances
   * @throws ApiError
   */
  public static exerciseControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<ExerciseWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/exercise',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
