/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Cholestrol } from '../models/Cholestrol';
import type { CholestrolPartial } from '../models/CholestrolPartial';
import type { CholestrolWithRelations } from '../models/CholestrolWithRelations';
import type { NewCholestrol } from '../models/NewCholestrol';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CholestrolControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static cholestrolControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CholestrolPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/cholestrol/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CholestrolWithRelations Cholestrol model instance
   * @throws ApiError
   */
  public static cholestrolControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CholestrolWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cholestrol/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static cholestrolControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/cholestrol/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Cholestrol Cholestrol model instance
   * @throws ApiError
   */
  public static cholestrolControllerCreate({
    requestBody,
  }: {
    requestBody?: NewCholestrol;
  }): CancelablePromise<Cholestrol> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/cholestrol',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CholestrolWithRelations Array of Cholestrol model instances
   * @throws ApiError
   */
  public static cholestrolControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<CholestrolWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/cholestrol',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
