import { Avatar } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ChatRoom } from 'generated';
import { ChatRoomSummaryResponse } from 'pages/chatPage/hooks/useGetChatRoomSummary';
import useUpdateChatroom from 'pages/chatPage/hooks/useUpdateChatroom';
import { useState } from 'react';
import { Notify } from 'utils/helpers';
import useDisclosure from 'utils/useDisclosure/useDisclosure';
import { ImageBlowup } from '../ChatArea/components/Messages/Messages';
import CheckFileType from '../ChatArea/components/Messages/checkFileType/CheckFileType';

const MemberPill = ({ onRemoveClick, member }: { onRemoveClick?: () => void; member }) => {
  return (
    <div className="flex w-max items-center h-max py-2 px-2 space-x-2 rounded-md bg-[#ECECEC] border border-[#D1D1D1]">
      <Avatar src={`${member?.image || 'https://picsum.photos/100'}`} className="!h-6 !w-6" />
      <div className=" space-x-3 flex items-center justify-between flex-1">
        <p>{member?.name}</p>
        <div
          onClick={() => {
            onRemoveClick();
          }}
          className="border-l-2"
        >
          <i className="mDoc-close-circle"></i>
        </div>
      </div>
    </div>
  );
};

const ChatDrawer = ({
  open,
  onClose,
  activeChatRoom,
  chatRoomSummary,
  refetchSummary,
}: {
  activeChatRoom?: ChatRoom;
  open?: boolean;
  onClose?: any;
  chatRoomSummary?: ChatRoomSummaryResponse;
  refetchSummary?: () => void;
}) => {
  const isGroup = activeChatRoom?.type === 'group';
  const removeUserHandler = useDisclosure();
  const [selectedImage, setSelectedImage] = useState('');
  const roomName = isGroup ? activeChatRoom?.groupName : chatRoomSummary?.users[0]?.name;
  const [activeMember, setActiveMember] = useState(null);
  const { updateChatroom, updatingChatroom } = useUpdateChatroom({
    callback: () => {
      Notify.success('User successfully removed from chatroom!');
      refetchSummary();
      removeUserHandler.onClose();
      setActiveMember(null);
    },
  });

  const imagePreviewHandler = useDisclosure();

  const removeUserFromGroup = (member) => {
    const getUpdatedUsers = activeChatRoom?.receiverIds?.filter((userId) => userId !== member.id);
    const updatedPayload = { ...activeChatRoom, receiverIds: getUpdatedUsers };

    updateChatroom({
      id: activeChatRoom?.id,
      requestBody: {
        ...updatedPayload,
      },
    });
  };

  const image = isGroup ? activeChatRoom?.imageUrl : chatRoomSummary?.users[0]?.image;
  return (
    <div
      className={`relative  bg-white border-l border-gray-100 h-full z-30`}
      style={{
        transition: '.5s linear',
      }}
    >
      <div className="flex justify-between items-center border-b border-gray-200">
        <div className="transition duration-500 mr-[1rem] absolute cursor-pointer left-[5px] z-50 border rounded-full w-6 h-6 flex items-center justify-center border-gray-300 bg-white">
          <i onClick={onClose} className={open ? 'mDoc-arrow-right-3' : 'mDoc-arrow-left-2'} />
        </div>
      </div>
      <section className="w-full h-full flex flex-col items-center">
        <div className="w-full h-full overflow-y-auto py-5">
          <section className="flex justify-center">
            <Avatar src={image} className="!h-32 !w-32" />
          </section>
          <div>
            <h4 className="font-semibold text-center my-3">{roomName}</h4>
            <div className="w-full">
              {isGroup ? (
                <div className="">
                  {/* <div className="border-b pb-6">
                    <p className="text-red-500 text-center text-sm cursor-pointer">Close group</p>
                  </div> */}
                  <div className="my-4 space-y-6 px-4 border-b py-5">
                    <div className="space-x-3 text-sm">
                      <span>Group participants</span>{' '}
                      <span className="font-semibold">{chatRoomSummary?.users?.length}</span>
                    </div>
                    <div className="space-y-3">
                      {chatRoomSummary?.users?.map((member) => (
                        <MemberPill
                          onRemoveClick={() => {
                            removeUserHandler.onOpen();
                            setActiveMember(member);
                          }}
                          key={member?.id}
                          member={member}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <section>
                  {/* <div className="space-y-2 text-center">
                    <h3 className="text-gray-600 text-sm">Health Status</h3>
                    <div className="text-[#4ABE82] flex items-center text-sm justify-center space-x-2">
                      <div className="h-2 w-2 bg-[#4ABE82] rounded-full"></div>
                      <span>Stable</span>
                    </div>
                  </div> */}
                  <div className="border-b py-3 w-full px-3 space-y-2">
                    <div className="space-y-1">
                      <p className="text-gray-600 font-semibold text-sm">Email</p>
                      <p className="text-sm">{chatRoomSummary?.users[0]?.email}</p>
                    </div>
                    <div className="space-y-1">
                      <p className="text-gray-600 font-semibold text-sm">Phone</p>
                      <p className="text-sm">{chatRoomSummary?.users[0]?.phone}</p>
                    </div>
                    <div className=" space-y-1">
                      <p className="text-gray-600 font-semibold text-sm">Program</p>
                      <p className="text-sm">
                        {chatRoomSummary?.users?.[0]?.subscriptions
                          ?.map((item) => item.program?.title)
                          .join(', ')}
                      </p>
                    </div>
                  </div>
                </section>
              )}
            </div>
            <div className="my-3 px-3 w-full">
              <h3 className="text-sm">Shared documents</h3>
              <div className="flex flex-wrap gap-2">
                {chatRoomSummary?.resources?.map((resource) => (
                  <div className="border rounded-lg border-orange/30 max-w-[250px]">
                    <CheckFileType
                      file={resource}
                      width="100px"
                      height="100px"
                      handleImageBlowup={() => {
                        setSelectedImage(resource);
                        imagePreviewHandler.onOpen();
                      }}
                      cursor="zoom-in"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImageBlowup
        selectedImage={selectedImage}
        isOpen={imagePreviewHandler.isOpen}
        closeBlowUp={() => {
          imagePreviewHandler.onClose();
          setSelectedImage('');
        }}
      />
      <ConfirmationModal
        title="Remove user"
        onSubmit={() => {
          removeUserFromGroup(activeMember);
        }}
        loading={updatingChatroom}
        description={`Are you sure you want to remove ${activeMember?.name} from ${activeChatRoom?.groupName}?`}
        isOpen={removeUserHandler.isOpen}
        onClose={() => {
          removeUserHandler.onClose();
          setActiveMember(null);
        }}
      />
    </div>
  );
};

export default ChatDrawer;
