import { Location, Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';

const PublicRoute = ({ children }) => {
  const location = useLocation() as { state: { from: Location } };
  const from = location.state?.from?.pathname || '/dashboard';
  const accessToken = useAppSelector((state) => state.user.accessToken);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const auth = !!(isLoggedIn && accessToken);
  return auth ? <Navigate to={from} replace /> : children;
};

export default PublicRoute;
