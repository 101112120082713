import TextEditor from 'components/TextEditor';
import Picker from 'emoji-picker-react';
import { useState } from 'react';
import OutsideAlerter from './outSideAlerter';

interface sendBarProps {
  message: string;
  handleMessageChange: (message: string) => void;
  onChangeImage?: (e) => void;
  sendChat?: (message: string) => void;
  disableSendButton?: boolean;
}
const SendBarComp = ({
  message,
  handleMessageChange,
  onChangeImage,
  sendChat,
  disableSendButton = true,
}: sendBarProps) => {
  const [emojiPicker, setEmojiPicker] = useState<boolean>(false);

  const handleEmojiPickerChange = (boolEmoji: boolean) => {
    setEmojiPicker(boolEmoji);
  };

  const setEmoji = (emoji, extra) => {
    handleMessageChange(message + emoji?.emoji);
  };

  const sendWithKeys = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendChat(message);
      handleMessageChange('');
    }
  };

  return (
    <section className="w-full h-full flex-1 p-3 bg-white">
      <div className="p-3 w-full h-full flex flex-col rounded-lg bg-transparent border">
        <div className="w-full">
          <TextEditor height={180} onEditorChange={(e) => handleMessageChange(e)} value={message} />
          {/* <textarea
            placeholder="Write a message..."
            className="w-full min-h-[30px] text-sm bg-transparent h-[45px] max-h-[100px] resize-none flex focus:ring-0 focus:outline-none rounded-md overflow-hidden"
            value={message}
            id="chatText"
            name="message"
            onChange={(e) => handleMessageChange(e.target.value)}
            onKeyDown={sendWithKeys}
          ></textarea> */}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="">
              <span className="linkBtn">
                <input
                  accept="image/*,video/*, application/pdf, audio/*"
                  style={{ display: 'none' }}
                  id={'file'}
                  type={'file'}
                  onChange={onChangeImage}
                />
                <label className={`mr-1 flex items-center cursor-pointer`} htmlFor={'file'}>
                  <i className="mDoc-paperclip"></i>
                </label>
              </span>
            </div>
            <div className="relative">
              {emojiPicker && (
                <div style={{ position: 'absolute', bottom: '50px' }}>
                  <OutsideAlerter close={() => handleEmojiPickerChange(false)}>
                    <Picker onEmojiClick={setEmoji} />
                  </OutsideAlerter>
                </div>
              )}
              <span className="linkBtn cursor-pointer" onClick={() => setEmojiPicker(true)}>
                <i className="mDoc-emoji-normal text-lg" />
              </span>
            </div>
          </div>

          <div className="ps-2">
            <button
              disabled={disableSendButton && !message}
              onClick={() => {
                sendChat(message);
                handleMessageChange('');
              }}
              className="text-green-400 font-semibold p-2 rounded-md px-4 flex items-center lg:space-x-2 disabled:opacity-50"
            >
              <span className="hidden lg:block">Send</span>
              <i className={`mDoc-send-1 lg:ms-1 `}></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendBarComp;
