import { apiWrapper } from 'api';
import {
  ChatRoom,
  ChatRoomControllerService,
  ChatRoomWithRelations,
  ChatWithRelations,
  NewChat,
} from 'generated';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUnreadChat, updateAppChatState } from 'redux/chat/actions';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { handleError } from 'utils/handleError';
import { Notify } from 'utils/helpers';
import chatSocket from 'utils/sockets/chatSocket';

interface IChatSocketContext {
  chatSocket?: any;
  incoming: ChatWithRelations | undefined;
  setIncoming: Dispatch<SetStateAction<ChatWithRelations | undefined>>;
  incomingReadMessage: ChatWithRelations | undefined;
  setIncomingReadMessage: Dispatch<SetStateAction<ChatWithRelations | undefined>>;
  setActiveChatRoom: Dispatch<SetStateAction<ChatRoom | undefined>>;
  activeChatRoom: ChatRoom | undefined;
  goOffline: () => void;
  goOnline: () => void;
  handleJoinRooms: (val: string[]) => void;
  readChatMessage: (data: { userId: string; chatId: string }) => void;
  newlyCreatedRooms: ChatRoom[];
  setNewlyCreatedRooms: Dispatch<SetStateAction<ChatRoom[]>>;
  incomingChatRoom: ChatRoomWithRelations;
  setIncomingChatRoom: Dispatch<SetStateAction<ChatRoomWithRelations>>;
  sendNewchat: (chat: NewChat, callback?: () => void) => void;
  sendBroadCast: (broadcastMessage: any, callback?: () => void) => void;
  createChatRoom: (chatroom: ChatRoom, callback?: () => void) => void;
  getChatRoom: (val: { id: string; callback?: () => void }) => Promise<ChatRoomWithRelations>;
}

const ChatSocketContext = createContext<IChatSocketContext>(null);

export function useChatSocketContext() {
  const context = useContext(ChatSocketContext);
  if (!context) {
    throw new Error('useChatSocketContext must be used within a ChatSocketProvider');
  }
  return context;
}

const getChatRoom = async ({ id, callback }: { id: string; callback?: () => void }) => {
  try {
    const data = await apiWrapper(() =>
      ChatRoomControllerService.chatRoomControllerGetSingleChatroom({ id, type: 'coach' }),
    );
    callback?.();
    return data;
  } catch (error) {
    handleError(error);
  }
};

const ChatSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const coach = useAppSelector((state) => state.user?.currentUser);
  const onlineMembers = useAppSelector((state) => state.chat?.onlineMembers);
  const unreadMessages = useAppSelector((state) => state.chat?.unreadMessages) || [];
  const [shouldRejoinRooms, setShouldRejoinRooms] = useState<boolean>(false);
  const [activeChatRoom, setActiveChatRoom] = useState<ChatRoom | undefined>(undefined);
  const [incoming, setIncoming] = useState<ChatWithRelations | undefined>(undefined);
  const [incomingReadMessage, setIncomingReadMessage] = useState<ChatWithRelations | undefined>(
    undefined,
  );
  const [incomingChatRoom, setIncomingChatRoom] = useState<ChatRoomWithRelations | undefined>(
    undefined,
  );
  const id = coach?.id;
  const currentPath = window.location.pathname;

  // In order to send messages to first timers that haven't joined a room,
  // the messages have to be sent to their userIds. We track newly created rooms to do so.
  const [newlyCreatedRooms, setNewlyCreatedRooms] = useState<ChatRoom[]>([]);

  useEffect(() => {
    chatSocket.connect();

    chatSocket.on('connect', () => {
      if (shouldRejoinRooms && coach?.id) {
        goOnline?.();
        setShouldRejoinRooms(false);
      }
    });

    return () => {
      chatSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    chatSocket.on('disconnect', () => {
      setShouldRejoinRooms(true);
    });
  }, []);

  useEffect(() => {
    chatSocket.on('online_user', (id) => {
      dispatch(
        updateAppChatState({
          onlineMembers: [...onlineMembers.filter((item) => item !== id), id],
        }),
      );
    });

    return () => {
      chatSocket.off('online_user');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    chatSocket.on('offline_user', (id) => {
      dispatch(
        updateAppChatState({
          onlineMembers: [...onlineMembers.filter((item) => item !== id)],
        }),
      );
    });

    return () => {
      chatSocket.off('offline_user');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    chatSocket.on('incoming_chat', (message) => {
      const newchat = JSON.parse(message);
      setIncoming?.(newchat);
      if (currentPath !== '/chat' && newchat.senderId !== coach?.id) {
        Notify.info(`${newchat.message}`, `${newchat?.senderName || 'New message'}`);
      }
      if (newchat.senderId !== coach?.id) {
        dispatch(addUnreadChat(newchat));
      }
    });

    return () => {
      chatSocket.off('incoming_chat');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChatRoom, currentPath]);

  useEffect(() => {
    chatSocket.on('incoming_chat_room', (chatroom) => {
      const newchatroom = JSON.parse(chatroom);
      handleJoinRooms([newchatroom.id]);
      setIncomingChatRoom(newchatroom);
      setActiveChatRoom(newchatroom);
      if (newchatroom['isNewRoom']) {
        setNewlyCreatedRooms((prev) => [...prev, newchatroom]);
      }
    });

    return () => {
      chatSocket.off('incoming_chat_room');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    chatSocket.on('incoming_read_chat_message', (message) => {
      const newchat = JSON.parse(message);
      setIncomingReadMessage?.(newchat);
    });

    return () => {
      chatSocket.off('incoming_read_chat_message');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goOnline = () => {
    chatSocket.emit('go_online', JSON.stringify({ type: 'coach', id: coach?.id }));
  };

  const goOffline = () => {
    chatSocket.emit('go_offline', coach?.id);
  };

  const handleJoinRooms = (data: string[]) => {
    const payload = data.join(',');
    chatSocket.emit('join_chat_rooms', payload);
  };

  const readChatMessage = (data: { userId: string; chatId: string }) => {
    const payload = JSON.stringify(data);
    chatSocket.emit('read_chat_message', payload);
  };

  const sendNewchat = (chat: NewChat, callback?: () => void) => {
    const isFirstMessage = !!newlyCreatedRooms.find((room) => room.id === chat.chatRoomId);
    const payload = JSON.stringify({ ...chat, isFirstMessage });
    chatSocket.emit('new_chat', payload);
    setNewlyCreatedRooms((prev) => prev.filter((room) => room.id !== chat.chatRoomId));
    callback?.();
  };

  const sendBroadCast = (broadcastMessage, callback?: () => void) => {
    const payload = JSON.stringify(broadcastMessage);
    chatSocket.emit('new_broadcast_message', payload);
    callback?.();
  };

  const createChatRoom = (chatroom: ChatRoom, callback?: () => void) => {
    const payload = JSON.stringify(chatroom);
    chatSocket.emit('new_chat_room', payload);
    callback?.();
  };

  return (
    <ChatSocketContext.Provider
      value={{
        chatSocket,
        incoming,
        setIncoming,
        activeChatRoom,
        setActiveChatRoom,
        handleJoinRooms,
        goOffline,
        goOnline,
        readChatMessage,
        newlyCreatedRooms,
        setNewlyCreatedRooms,
        incomingChatRoom,
        setIncomingChatRoom,
        sendNewchat,
        sendBroadCast,
        createChatRoom,
        incomingReadMessage,
        setIncomingReadMessage,
        getChatRoom,
      }}
    >
      {children}
    </ChatSocketContext.Provider>
  );
};

export default ChatSocketProvider;
