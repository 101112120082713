/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewSpeciality } from '../models/NewSpeciality';
import type { Speciality } from '../models/Speciality';
import type { SpecialityPartial } from '../models/SpecialityPartial';
import type { SpecialityWithRelations } from '../models/SpecialityWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpecialityControllerService {
  /**
   * @returns any Speciality model count
   * @throws ApiError
   */
  public static specialityControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/specialities/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static specialityControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Speciality;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/specialities/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static specialityControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: SpecialityPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/specialities/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SpecialityWithRelations Speciality model instance
   * @throws ApiError
   */
  public static specialityControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<SpecialityWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/specialities/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static specialityControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/specialities/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Speciality Speciality model instance
   * @throws ApiError
   */
  public static specialityControllerCreate({
    requestBody,
  }: {
    requestBody?: NewSpeciality;
  }): CancelablePromise<Speciality> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/specialities',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Speciality PATCH success count
   * @throws ApiError
   */
  public static specialityControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: SpecialityPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/specialities',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Speciality model instances
   * @throws ApiError
   */
  public static specialityControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<SpecialityWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/specialities',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
