/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewReferralReason } from '../models/NewReferralReason';
import type { ReferralReason } from '../models/ReferralReason';
import type { ReferralReasonWithRelations } from '../models/ReferralReasonWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferralReasonControllerService {
  /**
   * @returns ReferralReason Referral Reason model instance
   * @throws ApiError
   */
  public static referralReasonControllerCreate({
    requestBody,
  }: {
    requestBody?: NewReferralReason;
  }): CancelablePromise<ReferralReason> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/referral-reasons',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ReferralReasonWithRelations Array of Referral Reason model instances
   * @throws ApiError
   */
  public static referralReasonControllerGetAllReasons(): CancelablePromise<
    Array<ReferralReasonWithRelations>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/referral-reasons',
    });
  }
}
