/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LabTest } from '../models/LabTest';
import type { LabTestPartial } from '../models/LabTestPartial';
import type { LabTestWithRelations } from '../models/LabTestWithRelations';
import type { NewLabTest } from '../models/NewLabTest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LabTestControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static labTestControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: LabTestPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/lab-test/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns LabTest LabTest model instance
   * @throws ApiError
   */
  public static labTestControllerFindById({ id }: { id: string }): CancelablePromise<LabTest> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lab-test/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns LabTest LabTest model instance
   * @throws ApiError
   */
  public static labTestControllerCreate({
    requestBody,
  }: {
    requestBody?: NewLabTest;
  }): CancelablePromise<LabTest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/lab-test',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of LabTest model instances
   * @throws ApiError
   */
  public static labTestControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<LabTestWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lab-test',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
