import loadable, { lazy } from '@loadable/component';

function ComponentLoaderMiddleware(lazyComponent, name) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    lazyComponent()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
}

export function Loadable(importFunction, name = 'lazy') {
  return loadable(() => ComponentLoaderMiddleware(importFunction, name));
}

export function Lazy(importFunction, name = 'lazy') {
  return lazy(() => ComponentLoaderMiddleware(importFunction, name));
}
