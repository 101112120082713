import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => {
  return (
    <>
      <div className="w-full h-full">
        <div className="w-full m-[30px]">
          <SkeletonTheme highlightColor="#c2cad133">
            <Skeleton count={15} height={30} />
          </SkeletonTheme>
        </div>
      </div>
    </>
  );
};

export default SkeletonLoader;
