/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewUserFeedback } from '../models/NewUserFeedback';
import type { UserFeedbackWithRelations } from '../models/UserFeedbackWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserFeedbackControllerService {
  /**
   * @returns UserFeedbackWithRelations UserFeedback model instance
   * @throws ApiError
   */
  public static userFeedbackControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<UserFeedbackWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/userFeedback/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns NewUserFeedback Array of UserFeedbackAnswer excluding id and userFeedbackId
   * @throws ApiError
   */
  public static userFeedbackControllerCreate({
    userId,
    feedbackId,
    nudgeHubId,
    hubVisitId,
    requestBody,
  }: {
    userId?: string;
    feedbackId?: string;
    nudgeHubId?: string;
    hubVisitId?: string;
    requestBody?: Array<NewUserFeedback>;
  }): CancelablePromise<Array<NewUserFeedback>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/userFeedback',
      query: {
        userId: userId,
        feedbackId: feedbackId,
        nudgeHubId: nudgeHubId,
        hubVisitId: hubVisitId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserFeedbackWithRelations Array of UserFeedback model instances
   * @throws ApiError
   */
  public static userFeedbackControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<UserFeedbackWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/userFeedback',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
