import { Close } from '@mui/icons-material';
import { Drawer, styled } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

interface MuiDrawerProps {
  isOpen?: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: string;
  icon?: ReactNode;
  width?: string;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  variant?: 'permanent' | 'persistent' | 'temporary';
}

const useStyles = makeStyles({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: '100%',
    '@media (min-width: 600px)': {
      width: '350px',
    },
  },
});

const StyledDrawer = styled(Drawer)(
  ({ width }: { width?: string }) => ({
    '& .MuiDrawer-paper': {
      width: width || '300px', // Set default width to 300 if width prop is not provided
    },
  }),
  { name: 'MuiDrawer' }, // Name the component for better debugging in the browser devtools
);

const MuiDrawer: React.FC<MuiDrawerProps> = ({
  isOpen,
  variant,
  width,
  icon,
  onClose,
  anchor,
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <StyledDrawer
      aria-label="drawer"
      anchor={anchor || 'right'}
      open={isOpen}
      variant={variant}
      onClose={onClose}
      width={width}
      classes={{
        paper: classes.drawer,
      }}
    >
      <div>
        <div className="flex justify-between items-center px-3 h-10">
          {title && <h3 className="capitalize text-lg font-semibold">{title}</h3>}
          {icon && icon}
          <div
            onClick={onClose}
            className="bg-lightBlueShade hover:bg-blueShade cursor-pointer hover:text-white text-blueShade rounded-full w-max h-max p-2 flex items-center"
          >
            <Close className="!h-4 !w-4" />
          </div>
        </div>
        <div className="border-t mt-2 px-1 md:px-4">{children}</div>
      </div>
    </StyledDrawer>
  );
};

export default MuiDrawer;
