import avatar from 'assets/img/Avatar.svg';

interface IProps {
  src?: string;
  alt?: string;
  className?: string;
  badge?: React.ReactElement;
  badgePosition?: 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right';
  badgeClick?: () => void;
}

const Avatar = ({ src, alt, className, badge, badgePosition, badgeClick }: IProps) => {
  return (
    <div className={`relative h-full w-full ${className || ''}`}>
      <img
        className={`h-full w-full rounded-full ring-2 ring-white object-cover`}
        src={src || avatar}
        alt={alt || 'image'}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = avatar;
        }}
      />
      {badge ? (
        <div
          onClick={badgeClick && badgeClick}
          className={`absolute ${
            badgePosition === 'bottom-right'
              ? 'bottom-0 right-0'
              : badgePosition === 'bottom-left'
              ? 'bottom-0 left-0'
              : badgePosition === 'top-left'
              ? 'top-0 left-0'
              : badgePosition === 'top-right'
              ? 'top-0 right-0'
              : ''
          }`}
        >
          {badge}
        </div>
      ) : null}
    </div>
  );
};

export default Avatar;
