/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPartner } from '../models/NewPartner';
import type { Partner } from '../models/Partner';
import type { PartnerPartialExcluding_id_ } from '../models/PartnerPartialExcluding_id_';
import type { PartnerWithRelations } from '../models/PartnerWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnerControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static partnerControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PartnerPartialExcluding_id_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/partner/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PartnerWithRelations Partner model instance
   * @throws ApiError
   */
  public static partnerControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PartnerWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/partner/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static partnerControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/partner/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Partner Partner model instance
   * @throws ApiError
   */
  public static partnerControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPartner;
  }): CancelablePromise<Partner> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/partner',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Partner model instances
   * @throws ApiError
   */
  public static partnerControllerFindAll({
    filter,
    page,
    search,
  }: {
    filter?: any;
    page?: number;
    search?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<PartnerWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/partner',
      query: {
        filter: filter,
        page: page,
        search: search,
      },
    });
  }
}
