import { useGetRequest } from 'api/useGetRequest';
import { NotificationControllerService } from 'generated';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { updateAppNotificationState } from 'redux/notification/actions';

export const useGetNotifications = () => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector((state) => state.user.currentUser);

  useGetRequest({
    service: NotificationControllerService.notificationControllerFind,
    payload: {
      filter: {
        where: {
          isSeen: false,
          coachId: currentUser?.id,
        },
      },
    },
    tag: 'NotificationControllerService',
    onSuccess: (data) => {
      dispatch(
        updateAppNotificationState({
          unreadNotificationCount: data?.count ?? 0,
          unreadNotifications: data?.data || [],
        }),
      );
    },
  });
};
