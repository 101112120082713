/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPregnancyCycle } from '../models/NewPregnancyCycle';
import type { PregnancyCycle } from '../models/PregnancyCycle';
import type { PregnancyCyclePartial } from '../models/PregnancyCyclePartial';
import type { PregnancyCycleWithRelations } from '../models/PregnancyCycleWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PregnancyCycleControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static pregnancyCycleControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PregnancyCyclePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/pregnancy-cycle/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PregnancyCycleWithRelations PregnancyCycle model instance
   * @throws ApiError
   */
  public static pregnancyCycleControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PregnancyCycleWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pregnancy-cycle/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static pregnancyCycleControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/pregnancy-cycle/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PregnancyCycle PregnancyCycle model instance
   * @throws ApiError
   */
  public static pregnancyCycleControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPregnancyCycle;
  }): CancelablePromise<PregnancyCycle> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/pregnancy-cycle',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PregnancyCycleWithRelations Array of PregnancyCycle model instances
   * @throws ApiError
   */
  public static pregnancyCycleControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<PregnancyCycleWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pregnancy-cycle',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
