/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewOutlier } from '../models/NewOutlier';
import type { Outlier } from '../models/Outlier';
import type { OutlierPartialExcluding_created_at_id_userId_userFullName_ } from '../models/OutlierPartialExcluding_created_at_id_userId_userFullName_';
import type { OutlierWithRelations } from '../models/OutlierWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OutlierControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static outlierControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OutlierPartialExcluding_created_at_id_userId_userFullName_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/outlier/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OutlierWithRelations Outlier model instance
   * @throws ApiError
   */
  public static outlierControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<OutlierWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/outlier/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns Outlier Outlier model instance
   * @throws ApiError
   */
  public static outlierControllerCreate({
    requestBody,
  }: {
    requestBody?: NewOutlier;
  }): CancelablePromise<Outlier> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/outlier',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Outlier Statistics
   * @throws ApiError
   */
  public static outlierControllerOutlierStatistics({
    reviewerId,
  }: {
    reviewerId?: string;
  }): CancelablePromise<{
    in_review?: number;
    resolved?: number;
    pending?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/outlier-statistics',
      query: {
        reviewerId: reviewerId,
      },
    });
  }

  /**
   * @returns any Array of Outlier model instances
   * @throws ApiError
   */
  public static outlierControllerFindAll({
    filter,
    page,
    search,
  }: {
    filter?: any;
    page?: number;
    search?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<OutlierWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/outliers',
      query: {
        filter: filter,
        page: page,
        search: search,
      },
    });
  }

  /**
   * @returns any Array of Outlier model instances
   * @throws ApiError
   */
  public static outlierControllerFindAllOutliersByUserId({
    userId,
    filter,
    page,
  }: {
    userId?: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<OutlierWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/outliers-by-userId',
      query: {
        userId: userId,
        filter: filter,
        page: page,
      },
    });
  }
}
