/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentPartial } from '../models/CommentPartial';
import type { CommentWithRelations } from '../models/CommentWithRelations';
import type { NewComment } from '../models/NewComment';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static commentControllerAddDisLike({
    requestBody,
  }: {
    requestBody?: {
      userId?: string;
      commentId?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/addDislike',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static commentControllerAddLike({
    requestBody,
  }: {
    requestBody?: {
      userId?: string;
      commentId?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/addLike',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CommentWithRelations Comment model instance
   * @throws ApiError
   */
  public static commentControllerFindById({
    id,
  }: {
    id: string;
  }): CancelablePromise<CommentWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/comment/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Comment model count
   * @throws ApiError
   */
  public static commentControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/comments/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns CommentWithRelations Array of Comment model instances
   * @throws ApiError
   */
  public static commentControllerGetCommentReplies({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<CommentWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/comments/replies/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static commentControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CommentPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/comments/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static commentControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/comments/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Array of Comment model instances
   * @throws ApiError
   */
  public static commentControllerFind({
    resourceId,
    filter,
    page,
    reverse,
  }: {
    resourceId: string;
    filter?: any;
    page?: number;
    reverse?: boolean;
  }): CancelablePromise<
    Array<{
      currentPage?: number;
      totalPages?: number;
      count?: number;
      status?: string;
      data?: Array<CommentWithRelations>;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/comments/{resourceId}',
      path: {
        resourceId: resourceId,
      },
      query: {
        filter: filter,
        page: page,
        reverse: reverse,
      },
    });
  }

  /**
   * @returns CommentWithRelations Comment model instance
   * @throws ApiError
   */
  public static commentControllerCreate({
    requestBody,
  }: {
    requestBody?: NewComment;
  }): CancelablePromise<CommentWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/comments',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
