/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPsa } from '../models/NewPsa';
import type { Psa } from '../models/Psa';
import type { PsaPartial } from '../models/PsaPartial';
import type { PsaWithRelations } from '../models/PsaWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PsaControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static psaControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PsaPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/psa/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PsaWithRelations Psa model instance
   * @throws ApiError
   */
  public static psaControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PsaWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/psa/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static psaControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/psa/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Psa Psa model instance
   * @throws ApiError
   */
  public static psaControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPsa;
  }): CancelablePromise<Psa> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/psa',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PsaWithRelations Array of Psa model instances
   * @throws ApiError
   */
  public static psaControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<PsaWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/psa',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
