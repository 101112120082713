import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useField } from 'formik';
import React, { useState } from 'react';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';

interface IProps {
  containerProps?: React.HTMLProps<HTMLDivElement>;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  id?: string;
  rightIcon?: any;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (e: any) => void;
}

export const PasswordEyeIcon = ({
  handleTogglePassword,
  showPassword,
}: {
  handleTogglePassword: () => void;
  showPassword: boolean;
}) => {
  return (
    <div onClick={handleTogglePassword} className="!w-8 cursor-pointer flex justify-center">
      {showPassword ? (
        <Visibility className="!h-5 !w-5 !text-gray-700" />
      ) : (
        <VisibilityOff className="!h-5 !w-5 !text-gray-700" />
      )}
    </div>
  );
};

const FormInput = ({
  containerProps,
  label,
  rightIcon,
  required,
  placeholder,
  disabled,
  className,
  type,
  onKeyUp,
  onChange,
  ...rest
}: IProps) => {
  const [field, meta] = useField(rest);
  const fontSize = useBreakpointValue({
    base: '16px',
    md: '14px',
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className={className} {...containerProps}>
      {label && (
        <p className="mb-1 text-xs text-[#707070] uppercase">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </p>
      )}
      <div className="border flex  w-full bg-[#fbfbfb] p-2 rounded-md">
        <input
          className="outline-none bg-[#fbfbfb] border-none focus:ring-0 w-full"
          style={{
            fontSize,
          }}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          disabled={disabled}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          {...field}
          {...rest}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
        />
        {rightIcon ? (
          <i className="ml-2 icon-search text-gray-500"></i>
        ) : (
          type === 'password' && (
            <PasswordEyeIcon
              showPassword={showPassword}
              handleTogglePassword={handleTogglePassword}
            />
          )
        )}
      </div>
      {meta.touched && meta.error ? <div className="text-xs text-red-500">{meta.error}</div> : null}
    </div>
  );
};

export default FormInput;
