/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Hbaic } from '../models/Hbaic';
import type { HbaicPartial } from '../models/HbaicPartial';
import type { HbaicWithRelations } from '../models/HbaicWithRelations';
import type { NewHbaic } from '../models/NewHbaic';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HbaicControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static hbaicControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HbaicPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/hbaic/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HbaicWithRelations Hbaic model instance
   * @throws ApiError
   */
  public static hbaicControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<HbaicWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hbaic/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static hbaicControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/hbaic/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Hbaic Hbaic model instance
   * @throws ApiError
   */
  public static hbaicControllerCreate({
    requestBody,
  }: {
    requestBody?: NewHbaic;
  }): CancelablePromise<Hbaic> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/hbaic',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HbaicWithRelations Array of Hbaic model instances
   * @throws ApiError
   */
  public static hbaicControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<HbaicWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hbaic',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
