import { useGetRequest } from 'api/useGetRequest';
import { ChatRoomControllerService } from 'generated';

const useGetActiveChatRoom = ({
  receiverCoachId,
  receiverMemberId,
}: {
  receiverCoachId?: string;
  receiverMemberId?: string;
}) => {
  const { loading, data } = useGetRequest({
    service: ChatRoomControllerService.chatRoomControllerGetSummaryV2,
    tag: 'ChatRoomControllerService',
    payload: {
      receiverCoachId,
      receiverMemberId,
    },
    enabled: receiverCoachId?.length > 0 && receiverMemberId?.length > 0,
  });
  return { loading, data };
};

export default useGetActiveChatRoom;
