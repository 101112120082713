/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommunicationLog } from '../models/CommunicationLog';
import type { CommunicationLogExcluding_id_ } from '../models/CommunicationLogExcluding_id_';
import type { CommunicationLogPartial } from '../models/CommunicationLogPartial';
import type { CommunicationLogWithRelations } from '../models/CommunicationLogWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommunicationLogControllerService {
  /**
   * @returns CommunicationLog CommunicationLog model instance
   * @throws ApiError
   */
  public static communicationLogControllerBulkCreate({
    requestBody,
  }: {
    requestBody?: {
      emails: Array<string>;
      phoneNumbers: Array<string>;
      comment: string;
      entryDate: string;
      logType: 'hub_visit' | 'telegram_chat' | 'whatsapp_chat' | 'sms' | 'call' | 'email';
      time?: string;
      endTime?: string;
      logTime?: string;
      adminId?: string;
      coachId?: string;
      isBulk?: boolean;
      conversationType?: 'private' | 'group' | '';
    };
  }): CancelablePromise<CommunicationLog> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/bulk-communication-log',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CommunicationLogWithRelations Array of Communication Log model instances
   * @throws ApiError
   */
  public static communicationLogControllerGetByCoachId({
    coachId,
    filter,
    page,
  }: {
    coachId?: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<CommunicationLogWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/communication-log/byCoachId',
      query: {
        coachId: coachId,
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns CommunicationLogWithRelations Array of Communication Log model instances
   * @throws ApiError
   */
  public static communicationLogControllerGetByUserId({
    userId,
  }: {
    userId?: string;
  }): CancelablePromise<Array<CommunicationLogWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/communication-log/byUserId',
      query: {
        userId: userId,
      },
    });
  }

  /**
   * @returns CommunicationLogWithRelations Array of Communication Log model instances
   * @throws ApiError
   */
  public static communicationLogControllerGetByUserIdAndCoachId({
    userId,
    coachId,
  }: {
    userId?: string;
    coachId?: string;
  }): CancelablePromise<Array<CommunicationLogWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/communication-log/byUserIdAndCoachId',
      query: {
        userId: userId,
        coachId: coachId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static communicationLogControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CommunicationLogPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/communication-log/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static communicationLogControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/communication-log/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns CommunicationLog CommunicationLog model instance
   * @throws ApiError
   */
  public static communicationLogControllerCreate({
    requestBody,
  }: {
    requestBody?: CommunicationLogExcluding_id_;
  }): CancelablePromise<CommunicationLog> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/communication-log',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
