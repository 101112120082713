enum ChatActionTypes {
  UPDATE_NEW_GROUP_MEMBERS = 'UPDATE_NEW_GROUP_MEMBERS',
  ADD_ID_TO_DELETE = 'ADD_ID_TO_DELETE',
  ACTIVE_CHAT_ROOM_ID = 'ACTIVE_CHAT_ROOM_ID',
  READ_CHAT = 'READ_CHAT',
  ADD_UNREAD_CHAT = 'ADD_UNREAD_CHAT',
  UPDATE_APP_CHAT_STATE = 'UPDATE_APP_CHAT_STATE',
}

export default ChatActionTypes;
