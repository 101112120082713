/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewWeight } from '../models/NewWeight';
import type { Weight } from '../models/Weight';
import type { WeightPartialExcluding_id_defaultValue_defaultUnit_created_at_ } from '../models/WeightPartialExcluding_id_defaultValue_defaultUnit_created_at_';
import type { WeightWithRelations } from '../models/WeightWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WeightControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static weightControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: WeightPartialExcluding_id_defaultValue_defaultUnit_created_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/weight/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WeightWithRelations Weight model instance
   * @throws ApiError
   */
  public static weightControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<WeightWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weight/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static weightControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/weight/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Weight Weight model instance
   * @throws ApiError
   */
  public static weightControllerCreate({
    requestBody,
  }: {
    requestBody?: NewWeight;
  }): CancelablePromise<Weight> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/weight',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WeightWithRelations Array of Weight model instances
   * @throws ApiError
   */
  public static weightControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<WeightWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weight',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
