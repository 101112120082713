/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LabsProcedures } from '../models/LabsProcedures';
import type { LabsProceduresPartial } from '../models/LabsProceduresPartial';
import type { LabsProceduresWithRelations } from '../models/LabsProceduresWithRelations';
import type { NewLabsProcedures } from '../models/NewLabsProcedures';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LabsProceduresControllerService {
  /**
   * @returns any LabsProcedures model count
   * @throws ApiError
   */
  public static labsProceduresControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/labs-procedures/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static labsProceduresControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: LabsProcedures;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/labs-procedures/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static labsProceduresControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: LabsProceduresPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/labs-procedures/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns LabsProceduresWithRelations LabsProcedures model instance
   * @throws ApiError
   */
  public static labsProceduresControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<LabsProceduresWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/labs-procedures/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static labsProceduresControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/labs-procedures/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns LabsProcedures LabsProcedures model instance
   * @throws ApiError
   */
  public static labsProceduresControllerCreate({
    requestBody,
  }: {
    requestBody?: NewLabsProcedures;
  }): CancelablePromise<LabsProcedures> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/labs-procedures',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any LabsProcedures PATCH success count
   * @throws ApiError
   */
  public static labsProceduresControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: LabsProceduresPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/labs-procedures',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns LabsProceduresWithRelations Array of LabsProcedures model instances
   * @throws ApiError
   */
  public static labsProceduresControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<LabsProceduresWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/labs-procedures',
      query: {
        filter: filter,
      },
    });
  }
}
