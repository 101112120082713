import Button from 'components/Buttons/Button';
import FormInput from 'components/FormInput/FormInput';
import ModalComponent from 'components/Modal/Modal';
import { Form, Formik } from 'formik';
import useProfileUpdate from 'pages/profile/hooks/useProfileUpdate';
import * as Yup from 'yup';

interface IProps {
  isOpen?: boolean;
  onClose: () => void;
  callback?: () => void;
  coachId?: string;
  userId?: string;
}

const ChangePasswordModal = ({ isOpen, onClose, callback, coachId, userId }: IProps) => {
  const { loading, updateProfile } = useProfileUpdate();
  return (
    <>
      <ModalComponent title="Change Password" size="sm" isOpen={isOpen} onClose={onClose}>
        <div className="p-4">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object({
              password: Yup.string().required('This field is required'),
              confirmPassword: Yup.string()
                .required('This field is required')
                .test('password', 'Both passwords must match', function (value) {
                  return this.parent.password === value;
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              updateProfile({ password: values.password });
            }}
          >
            {(props) => (
              <Form>
                <div className="flex flex-col md:flex-row justify-between flex-wrap">
                  <div className="w-full mb-4">
                    <FormInput name="password" type="password" label="New Password" required />
                  </div>

                  <div className="w-full my-4" />

                  <div className="w-full mb-4">
                    <FormInput
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                      required
                    />
                  </div>

                  <div className="w-full my-4" />
                  <div className="w-full flex flex-row justify-end mt-6">
                    <Button type="button" isLoading={loading} onClick={() => props.submitForm()}>
                      Save changes
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </>
  );
};

export default ChangePasswordModal;
