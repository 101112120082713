import Loader from 'components/Loader/Loader';
import ModalComponent from 'components/Modal/Modal';
import CheckFileType from '../components/ChatArea/components/Messages/checkFileType/CheckFileType';
import SendBarComp from '../components/ChatArea/components/SendBar/SendBar';

interface PreviewImageProps {
  data: any;
  preview: string;
  message: string;
  isOpen: boolean;
  fileName?: string;
  onClose: () => void;
  sendImageMessage: (e: any) => void;
  fileType: string;
  handleMessageChange: (message: string) => void;
  loading: boolean;
  onChangeImage: (e: any) => void;
}
const PreviewImageModal = ({
  data,
  preview,
  message,
  isOpen,
  onClose,
  sendImageMessage,
  fileType,
  handleMessageChange,
  fileName,
  loading,
  onChangeImage,
}: PreviewImageProps) => {
  return (
    <ModalComponent title="Preview Image" size="lg" isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        <div className="rounded-md" style={{ width: '100%' }}>
          {loading ? (
            <Loader />
          ) : (
            <div className="border rounded-lg border-orange/30">
              <CheckFileType
                noBlowUp
                file={preview}
                fileName={fileName}
                fileType={fileType}
                width={'100%'}
                height={'250px'}
              />
            </div>
          )}
        </div>
        <SendBarComp
          message={message}
          handleMessageChange={handleMessageChange}
          onChangeImage={onChangeImage}
          sendChat={(e) => {
            sendImageMessage(e);
            onClose();
          }}
          disableSendButton={false}
        />
      </div>
    </ModalComponent>
  );
};

export default PreviewImageModal;
