/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BloodPressure } from '../models/BloodPressure';
import type { BloodPressurePartial } from '../models/BloodPressurePartial';
import type { BloodPressureWithRelations } from '../models/BloodPressureWithRelations';
import type { NewBloodPressure } from '../models/NewBloodPressure';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BloodPressureControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static bloodPressureControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: BloodPressurePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/blood-pressure/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BloodPressureWithRelations BloodPressure model instance
   * @throws ApiError
   */
  public static bloodPressureControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BloodPressureWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blood-pressure/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static bloodPressureControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/blood-pressure/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns BloodPressure BloodPressure model instance
   * @throws ApiError
   */
  public static bloodPressureControllerCreate({
    requestBody,
  }: {
    requestBody?: NewBloodPressure;
  }): CancelablePromise<BloodPressure> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/blood-pressure',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BloodPressureWithRelations Array of BloodPressure model instances
   * @throws ApiError
   */
  public static bloodPressureControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<BloodPressureWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blood-pressures',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
