import { NotificationManager } from 'react-notifications';

interface IObj {
  code: number;
  message: string;
}

interface ErrorResponse {
  url?: string;
  status?: number;
  statusText?: string;
  body?: {
    error?: {
      statusCode?: number;
      name?: string;
      message?: string;
    };
    message?: string;
  };
  response?: {
    data?: {
      error?: {
        message?: string;
      };
      message?: string;
    };
    status?: number;
  };
}

const DEFAULT_MESSAGES = {
  '404': "We can't find the resource you are looking for.",
  '422': 'Bad entry, please crosscheck your data.',
  '400': 'Sorry, an unexpected error occurred. Please try again.',
  '600': 'Sorry, an error occurred. Please check your internet connection and try again.',
  '500':
    'Sorry, something went wrong. Our engineers are working to fix it. Please contact support if needed.',
  '401': '',
  '403': 'Sorry, you do not have permission to access this resource.',
  '408': 'Sorry, your request took too long to process, please try again.',
  '502': 'Sorry, we are experiencing technical difficulties. Service will be restored soon.',
  '503': 'Sorry, we are experiencing technical difficulties. Service will be restored soon.',
  '504': 'Sorry, your request took too long to process, please try again.',
};

export const handleError = (
  error: ErrorResponse,
  customErrors?: IObj | IObj[],
  showNotification = true,
  override401 = false,
) => {
  // Log error in development
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }

  // Extract status code and message from error response
  const statusCode =
    error?.body?.error?.statusCode || error?.response?.status || error?.status || 600;

  const errorMessage =
    error?.body?.error?.message ||
    error?.body?.message ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.message;

  // Initialize messages object with defaults
  const messages = { ...DEFAULT_MESSAGES };

  // Add custom error messages if provided
  if (customErrors) {
    if (Array.isArray(customErrors)) {
      customErrors.forEach(({ code, message }) => {
        messages[code] = message;
      });
    } else {
      messages[customErrors.code] = customErrors.message;
    }
  }

  // Handle 409 conflicts specially
  if (statusCode === 409 && errorMessage) {
    messages[statusCode] = errorMessage;
  }

  // Get final message to display
  const finalMessage = errorMessage || messages[statusCode] || messages['500'];

  // Handle 401 unauthorized
  if (statusCode === 401 && messages['401'] === '') {
    return;
  }

  // Show notification if enabled and conditions met
  if (showNotification && (statusCode !== 401 || override401)) {
    NotificationManager.error(finalMessage);
  }

  return {
    success: false,
    statusCode,
    message: finalMessage,
  };
};
