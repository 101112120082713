import { useMutateRequest } from 'api/useMutation';
import { ChatRoomControllerService, ChatRoomPartial } from 'generated';
import React from 'react';

const useUpdateChatroom = ({
  id,
  payload,
  callback,
}: {
  id?: string;
  payload?: ChatRoomPartial;
  callback?: () => void;
}) => {
  const { trigger, loading } = useMutateRequest({
    service: ChatRoomControllerService.chatRoomControllerUpdateById,
    tag: 'chatRoomControllerUpdateById',
    payload: {
      id,
      requestBody: {
        ...payload,
      },
    },
    onSuccess: () => {
      callback?.();
    },
  });
  return {
    updateChatroom: trigger,
    updatingChatroom: loading,
  };
};

export default useUpdateChatroom;
