/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Height } from '../models/Height';
import type { HeightPartialExcluding_id_defaultValue_defaultUnit_modified_at_ } from '../models/HeightPartialExcluding_id_defaultValue_defaultUnit_modified_at_';
import type { HeightWithRelations } from '../models/HeightWithRelations';
import type { NewHeight } from '../models/NewHeight';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HeightControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static heightControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HeightPartialExcluding_id_defaultValue_defaultUnit_modified_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/height/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HeightWithRelations Height model instance
   * @throws ApiError
   */
  public static heightControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<HeightWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/height/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static heightControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/height/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Height Height model instance
   * @throws ApiError
   */
  public static heightControllerCreate({
    requestBody,
  }: {
    requestBody?: NewHeight;
  }): CancelablePromise<Height> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/height',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Height model instances
   * @throws ApiError
   */
  public static heightControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<HeightWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/height',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
