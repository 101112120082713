import DashboardHeader from 'components/DashboardLayout/DashboardHeader';
import PageContainer from 'components/DashboardLayout/PageContainer';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
}

const SettingsLayout = ({ children }: IProps) => {
  const { pathname } = useLocation();

  const links = [
    {
      title: 'Profile Information',
      to: '/settings/profile',
      isActive: pathname === '/settings/profile',
    },
    {
      title: 'Change Password',
      to: '/settings/change-password',
      isActive: pathname === '/settings/change-password',
    },
    {
      title: 'Privacy Settings',
      to: '/settings/privacy',
      isActive: pathname === '/settings/privacy',
    },
    {
      title: 'Check-In to Hub',
      to: '/settings/check-in',
      isActive: pathname === '/settings/check-in',
    },
  ];
  return (
    <div>
      <DashboardHeader pageTitle="Account Settings" icon="mDoc-profile-circle" />
      <PageContainer>
        <div className="flex border rounded-lg border-gray-300">
          <div className="w-[20%] border-r border-r-gray-300 py-5 text-sm">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                className={`${
                  link.isActive ? 'border-r-2 border-r-orange text-orange' : 'text-gray-400'
                } w-full px-5 py-3 block font-bold ml-[1.5px] transition duration-500`}
              >
                {link.title}
              </Link>
            ))}
          </div>
          <div className="w-[80%] py-8 px-3">{children}</div>
        </div>
      </PageContainer>
    </div>
  );
};

export default SettingsLayout;
