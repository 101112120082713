/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Medication } from '../models/Medication';
import type { MedicationPartial } from '../models/MedicationPartial';
import type { MedicationWithRelations } from '../models/MedicationWithRelations';
import type { NewMedication } from '../models/NewMedication';
import type { UsersWithRelations } from '../models/UsersWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MedicationControllerService {
  /**
   * @returns any Medication model count
   * @throws ApiError
   */
  public static medicationControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medications/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns UsersWithRelations Array of Users model instances
   * @throws ApiError
   */
  public static medicationControllerGetMedicationUsers({
    id,
    filter,
    page,
  }: {
    id: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<UsersWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medications/users/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Medication;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/medications/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: MedicationPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/medications/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MedicationWithRelations Medication model instance
   * @throws ApiError
   */
  public static medicationControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<MedicationWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medications/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static medicationControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/medications/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Medication Medication model instance
   * @throws ApiError
   */
  public static medicationControllerCreate({
    requestBody,
  }: {
    requestBody?: NewMedication;
  }): CancelablePromise<Medication> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/medications',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Medication PATCH success count
   * @throws ApiError
   */
  public static medicationControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: MedicationPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/medications',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MedicationWithRelations Array of Medication model instances
   * @throws ApiError
   */
  public static medicationControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<MedicationWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/medications',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
