/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForumPartial } from '../models/ForumPartial';
import type { ForumUserWithRelations } from '../models/ForumUserWithRelations';
import type { ForumWithRelations } from '../models/ForumWithRelations';
import type { NewForum } from '../models/NewForum';
import type { NewForumUser } from '../models/NewForumUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ForumControllerService {
  /**
   * @returns ForumUserWithRelations ForumUser model instance
   * @throws ApiError
   */
  public static forumControllerUpdateForumParticipants({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Array<NewForumUser>;
  }): CancelablePromise<Array<ForumUserWithRelations>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/forum/participants/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ForumWithRelations Get forums by userId
   * @throws ApiError
   */
  public static forumControllerGetUserForums({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<Array<ForumWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/forum/user/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static forumControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ForumPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/forum/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ForumWithRelations Forum model instance
   * @throws ApiError
   */
  public static forumControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ForumWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/forum/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static forumControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/forum/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ForumWithRelations Forum model instance
   * @throws ApiError
   */
  public static forumControllerCreate({
    requestBody,
  }: {
    requestBody?: NewForum;
  }): CancelablePromise<ForumWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/forum',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ForumWithRelations Array of Forum model instances
   * @throws ApiError
   */
  public static forumControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<ForumWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/forum',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
