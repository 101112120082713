/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoachUserSubscriptions } from '../models/CoachUserSubscriptions';
import type { CoachUserSubscriptionsPartial } from '../models/CoachUserSubscriptionsPartial';
import type { CoachUserSubscriptionsWithRelations } from '../models/CoachUserSubscriptionsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoachUserSubscriptionsControllerService {
  /**
   * @returns CoachUserSubscriptions CoachUserSubscriptions model instance
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerCreateBulk({
    requestBody,
  }: {
    requestBody?: {
      programId?: string;
      coachId?: string;
      fromCoachId?: string;
      userSubscriptionIds?: Array<string>;
    };
  }): CancelablePromise<Array<CoachUserSubscriptions>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coach-user-subscriptions/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerReassignAllMembers({
    requestBody,
  }: {
    requestBody?: {
      fromCoachId?: string;
      toCoachId?: string;
      programId?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coach-user-subscriptions/reassign',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CoachUserSubscriptionsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coach-user-subscriptions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachUserSubscriptionsWithRelations CoachUserSubscriptions model instance
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CoachUserSubscriptionsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coach-user-subscriptions/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerUnassign({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/coach-user-subscriptions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns CoachUserSubscriptions CoachUserSubscriptions model instance
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerCreate({
    requestBody,
  }: {
    requestBody?: CoachUserSubscriptions;
  }): CancelablePromise<CoachUserSubscriptions> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coach-user-subscriptions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of CoachUserSubscriptions model instances
   * @throws ApiError
   */
  public static coachUserSubscriptionsControllerFindAll({
    filter,
    page,
    programId,
    includeRelationships,
    startDate,
    endDate,
  }: {
    filter?: any;
    page?: number;
    programId?: string;
    includeRelationships?: boolean;
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<CoachUserSubscriptionsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coach-user-subscriptions',
      query: {
        filter: filter,
        page: page,
        programId: programId,
        includeRelationships: includeRelationships,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
}
