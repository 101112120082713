/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewTemperature } from '../models/NewTemperature';
import type { Temperature } from '../models/Temperature';
import type { TemperaturePartialExcluding_id_defaultValue_defaultUnit_modified_at_ } from '../models/TemperaturePartialExcluding_id_defaultValue_defaultUnit_modified_at_';
import type { TemperatureWithRelations } from '../models/TemperatureWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemperatureControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static temperatureControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: TemperaturePartialExcluding_id_defaultValue_defaultUnit_modified_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/temperature/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns TemperatureWithRelations Temperature model instance
   * @throws ApiError
   */
  public static temperatureControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<TemperatureWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/temperature/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static temperatureControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/temperature/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Temperature Temperature model instance
   * @throws ApiError
   */
  public static temperatureControllerCreate({
    requestBody,
  }: {
    requestBody?: NewTemperature;
  }): CancelablePromise<Temperature> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/temperature',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Temperature model instances
   * @throws ApiError
   */
  public static temperatureControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<TemperatureWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/temperature',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
