/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Payments } from '../models/Payments';
import type { Programs } from '../models/Programs';
import type { UserSubscriptions } from '../models/UserSubscriptions';
import type { UserSubscriptionsPartial } from '../models/UserSubscriptionsPartial';
import type { UserSubscriptionsWithRelations } from '../models/UserSubscriptionsWithRelations';
import type { Voucher } from '../models/Voucher';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserSubscriptionsControllerService {
  /**
   * @returns any Subscription page statistics
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetSubscriptionPageStats(): CancelablePromise<{
    totalActive?: number;
    totalInactive?: number;
    total?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/SubscriptionPageStats',
    });
  }

  /**
   * @returns any UserSubscriptions model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetCoachUserSubscription({
    coachId,
    search,
    limit,
    page,
    startDate,
    endDate,
    filter,
  }: {
    coachId: string;
    search?: string;
    limit?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    filter?: any;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<UserSubscriptionsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/coach/{coachId}',
      path: {
        coachId: coachId,
      },
      query: {
        search: search,
        limit: limit,
        page: page,
        startDate: startDate,
        endDate: endDate,
        filter: filter,
      },
    });
  }

  /**
   * @returns Voucher Voucher model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerRecordOfflinePayment({
    requestBody,
  }: {
    requestBody?: {
      userId?: string;
      programId?: string;
      planId?: string;
      paymentType?: 'cash' | 'POS';
      paymentDate?: string;
      reference?: string;
    };
  }): CancelablePromise<Voucher> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user-subscriptions/offline-payment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any UserSubscriptions model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerProgramStatistics({
    programIds,
  }: {
    programIds?: string;
  }): CancelablePromise<
    Array<{
      program?: Programs;
      totalSubscriptions?: number;
      totalRevenue?: number;
      totalCoaches?: number;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/program-statistics',
      query: {
        programIds: programIds,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static userSubscriptionsControllerUpdateStageOfChange({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: {
      changeStage?: 'precontemplation' | 'contemplation' | 'preparation' | 'action' | 'maintenance';
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user-subscriptions/user/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserSubscriptionsWithRelations Array of UserSubscriptions model instances
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetUserSubscriptions({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<Array<UserSubscriptionsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/user/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns UserSubscriptionsWithRelations UserSubscriptions model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetUserSubscriptionsWithoutCoaches({
    programId,
  }: {
    programId?: string;
  }): CancelablePromise<Array<UserSubscriptionsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/without-coaches',
      query: {
        programId: programId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static userSubscriptionsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: UserSubscriptionsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user-subscriptions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserSubscriptionsWithRelations UserSubscriptions model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetSubscriptionById({
    id,
  }: {
    id: string;
  }): CancelablePromise<UserSubscriptionsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns UserSubscriptions UserSubscriptions model instance
   * @throws ApiError
   */
  public static userSubscriptionsControllerCreate({
    requestBody,
  }: {
    requestBody?: {
      paymentData?: Payments;
    };
  }): CancelablePromise<UserSubscriptions> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user-subscriptions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of UserSubscriptions model instances
   * @throws ApiError
   */
  public static userSubscriptionsControllerGetAllSubscriptions({
    limit,
    page,
    startDate,
    endDate,
    search,
    programId,
    status,
  }: {
    limit?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    search?: string;
    programId?: string;
    status?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<UserSubscriptions>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-subscriptions',
      query: {
        limit: limit,
        page: page,
        startDate: startDate,
        endDate: endDate,
        search: search,
        programId: programId,
        status: status,
      },
    });
  }
}
