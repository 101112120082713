import { ChatWithRelations } from 'generated';
import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import ChatActionTypes from './types';

export type IAppChatState = {
  error?: string;
  activeChatRoomId?: string;
  unreadMessages?: ChatWithRelations[];
  newGroupMembers?: any[];
  onlineMembers?: string[];
};

export const INITIAL_CHAT_STATE: IAppChatState = {
  error: '',
  activeChatRoomId: '',
  unreadMessages: [],
  onlineMembers: [],
};

function chatReducer(state: IAppChatState = INITIAL_CHAT_STATE, { type, payload }) {
  switch (type) {
    case ChatActionTypes.ADD_UNREAD_CHAT:
      return {
        ...state,
        unreadMessages: [...(state.unreadMessages || []), payload],
      };
    case ChatActionTypes.UPDATE_APP_CHAT_STATE:
      return {
        ...state,
        ...payload,
      };
    case ChatActionTypes.READ_CHAT:
      return {
        ...state,
        unreadMessages: state.unreadMessages.filter((item) => item.id !== payload.id),
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'chat',
  storage,
  blacklist: ['error', 'isLoading', 'loading'],
};

export default persistReducer(persistConfig, chatReducer);
