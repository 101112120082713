/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewNudgeHubs } from '../models/NewNudgeHubs';
import type { NudgeHubs } from '../models/NudgeHubs';
import type { NudgeHubsPartial } from '../models/NudgeHubsPartial';
import type { NudgeHubsWithRelations } from '../models/NudgeHubsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NudgeHubsControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static nudgeHubsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: NudgeHubsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/nudge-hubs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns NudgeHubs NudgeHubs model instance
   * @throws ApiError
   */
  public static nudgeHubsControllerFindById({ id }: { id: string }): CancelablePromise<NudgeHubs> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nudge-hubs/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static nudgeHubsControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nudge-hubs/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns NudgeHubs NudgeHubs model instance
   * @throws ApiError
   */
  public static nudgeHubsControllerCreate({
    requestBody,
  }: {
    requestBody?: NewNudgeHubs;
  }): CancelablePromise<NudgeHubs> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nudge-hubs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of NudgeHubs model instances
   * @throws ApiError
   */
  public static nudgeHubsControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<NudgeHubsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nudge-hubs',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
