/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feedback } from '../models/Feedback';
import type { FeedbackPartial } from '../models/FeedbackPartial';
import type { FeedbackWithRelations } from '../models/FeedbackWithRelations';
import type { NewFeedback } from '../models/NewFeedback';
import type { UserFeedback } from '../models/UserFeedback';
import type { UserFeedbackExcluding_id_ } from '../models/UserFeedbackExcluding_id_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackControllerService {
  /**
   * @returns UserFeedback UserFeedback model instance
   * @throws ApiError
   */
  public static feedbackControllerCreateContactUs({
    requestBody,
  }: {
    requestBody?: UserFeedbackExcluding_id_;
  }): CancelablePromise<UserFeedback> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/contact-us',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static feedbackControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: FeedbackPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/feedback/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FeedbackWithRelations Feedback model instance
   * @throws ApiError
   */
  public static feedbackControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<FeedbackWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/feedback/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static feedbackControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/feedback/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Feedback Feedback model instance
   * @throws ApiError
   */
  public static feedbackControllerCreate({
    requestBody,
  }: {
    requestBody?: NewFeedback;
  }): CancelablePromise<Feedback> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/feedback',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FeedbackWithRelations Array of Feedback model instances
   * @throws ApiError
   */
  public static feedbackControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<FeedbackWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/feedback',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
