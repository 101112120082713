import axios from 'axios';
import localforage from 'localforage';
import { store } from 'redux/store';
import { logoutUser, updateAppUserState } from 'redux/user/actions';

const config = {
  development: {
    REACT_APP_API_URL: 'https://mobile-api-dev.mymdoc.com',
  },
  staging: {
    REACT_APP_API_URL: 'https://mobile-api-staging.mymdoc.com',
  },
  production: {
    REACT_APP_API_URL: 'https://mobile-api.mymdoc.com',
  },
};

export const { REACT_APP_API_URL } = config[process.env.REACT_APP_ENV || process.env.NODE_ENV];
// export const REACT_APP_API_URL = 'http://localhost:5055';

export const httpClient = axios.create({
  baseURL: REACT_APP_API_URL,
});

let isRefreshingToken = false;

const refreshExpiredToken = async (refresh_token) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}/coaches/refresh-token?token=${refresh_token}`,
    );
    return data;
  } catch (error) {
    store?.dispatch(logoutUser());
    return undefined;
  }
};

export async function httpRequest<T>(request: () => Promise<T>): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request();
      resolve(response);
    } catch (error) {
      if (error?.response?.status === 401 || error?.status === 401) {
        // refresh token here
        const originalRequest = error.response?.config || error.body?.config;

        if (!isRefreshingToken) {
          isRefreshingToken = true;
          const refreshToken = await localforage.getItem('refreshToken');
          delete httpClient.defaults.headers.common['Authorization'];
          try {
            const res = await refreshExpiredToken(refreshToken);
            if (res) {
              const { accessToken: token, refreshToken } = res;
              localforage.setItem('refreshToken', refreshToken);
              localforage.setItem('isRefreshingToken', false);
              isRefreshingToken = false;
              localforage.setItem('accessToken', token);
              httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
              store?.dispatch(
                updateAppUserState({
                  accessToken: token,
                }),
              );
              // @ts-ignore
              originalRequest.headers.Authorization = `Bearer ${token}`;
              const response: any = await axios(originalRequest);
              resolve(response?.data || response);
            } else {
              isRefreshingToken = false;
            }
          } catch (error2) {
            isRefreshingToken = false;
            await localforage.setItem('isRefreshingToken', false);
            reject(error);
          }
        } else {
          setTimeout(async () => {
            const new_token = await localforage.getItem('accessToken');
            // @ts-ignore
            originalRequest.headers.Authorization = `Bearer ${new_token}`;
            const response: any = await axios(originalRequest);
            resolve(response?.data || response);
          }, 2000);
        }
      } else {
        reject(error);
      }
    }
  });
}

function timeoutPromise<T>(ms: number, promise: Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      const error: any = new Error('We experienced a timeout, please try again.');
      error.name = 'TimeoutError';
      error.response = {
        data: {
          message: 'We experienced a timeout, please try again.',
        },
        status: 409,
      };
      reject(error);
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });
}

export async function apiWrapper<T>(request: () => Promise<T>) {
  const response = await timeoutPromise(60000, httpRequest(request));
  return response;
}

export const handleAccessToken = async () => {
  const token = await localforage.getItem('accessToken');
  httpClient.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
};
