/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewUserMentalAssessment } from '../models/NewUserMentalAssessment';
import type { UserMentalAssessment } from '../models/UserMentalAssessment';
import type { UserMentalAssessmentPartial } from '../models/UserMentalAssessmentPartial';
import type { UserMentalAssessmentRecordWithRelations } from '../models/UserMentalAssessmentRecordWithRelations';
import type { UserMentalAssessmentWithRelations } from '../models/UserMentalAssessmentWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserMentalAssessmentControllerService {
  /**
   * @returns any UserMentalAssessment model instance
   * @throws ApiError
   */
  public static userMentalAssessmentControllerCreateBulk({
    requestBody,
  }: {
    requestBody?: Array<NewUserMentalAssessment>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user-mental-assessment/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static userMentalAssessmentControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: UserMentalAssessmentPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user-mental-assessment/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserMentalAssessmentWithRelations UserMentalAssessment model instance
   * @throws ApiError
   */
  public static userMentalAssessmentControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<UserMentalAssessmentWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-mental-assessment/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static userMentalAssessmentControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/user-mental-assessment/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns UserMentalAssessment UserMentalAssessment model instance
   * @throws ApiError
   */
  public static userMentalAssessmentControllerCreate({
    requestBody,
  }: {
    requestBody?: NewUserMentalAssessment;
  }): CancelablePromise<UserMentalAssessment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user-mental-assessment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserMentalAssessmentWithRelations Array of UserMentalAssessment model instances
   * @throws ApiError
   */
  public static userMentalAssessmentControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<UserMentalAssessmentWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-mental-assessment',
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns any Check if mental health assessment record exists
   * @throws ApiError
   */
  public static userMentalAssessmentControllerCheckIfMentalHealthAssessmentRecordExists({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<{
    gadExists?: boolean;
    phqExists?: boolean;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-mental-assessment-record/exists/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns UserMentalAssessmentRecordWithRelations UserMentalAssessmentRecord model instance
   * @throws ApiError
   */
  public static userMentalAssessmentControllerUserMentalAssessmentRecordById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<UserMentalAssessmentRecordWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-mental-assessment-record/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any Array of UserMentalAssessment model iinanstances
   * @throws ApiError
   */
  public static userMentalAssessmentControllerFindAllUserMentalAssessmentRecord({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<UserMentalAssessmentRecordWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user-mental-assessment-record',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
