/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BloodPressure } from '../models/BloodPressure';
import type { BloodSugar } from '../models/BloodSugar';
import type { Bmi } from '../models/Bmi';
import type { Cholestrol } from '../models/Cholestrol';
import type { Exercise } from '../models/Exercise';
import type { EyeExamination } from '../models/EyeExamination';
import type { FootExamination } from '../models/FootExamination';
import type { Hbaic } from '../models/Hbaic';
import type { HealthDiary } from '../models/HealthDiary';
import type { HealthDiaryPartial } from '../models/HealthDiaryPartial';
import type { HealthDiaryWithRelations } from '../models/HealthDiaryWithRelations';
import type { MedicationPassport } from '../models/MedicationPassport';
import type { NewHealthDiary } from '../models/NewHealthDiary';
import type { PregnancyCycle } from '../models/PregnancyCycle';
import type { Psa } from '../models/Psa';
import type { Temperature } from '../models/Temperature';
import type { WaistCircumference } from '../models/WaistCircumference';
import type { Weight } from '../models/Weight';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HealthDiaryControllerService {
  /**
   * @returns any HealthDiary model count
   * @throws ApiError
   */
  public static healthDiaryControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerDashboardHealthDiary({
    type,
  }: {
    type?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/dashboardHealthDiary',
      query: {
        type: type,
      },
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerGetGlucoseDetailsByUsreId({
    mealType,
  }: {
    mealType?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/getGlucoseDetailsByUsreId',
      query: {
        mealType: mealType,
      },
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerGetHealthPassportByUserId({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/getHealthPassportByUserId/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerGetUserHealthDiaryById({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/getUserHealthDiaryById/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerUpdateNumberToString(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/updateNumberToString',
    });
  }

  /**
   * @returns any HealthDiary  success
   * @throws ApiError
   */
  public static healthDiaryControllerUserHealthPassportReports(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/userHealthPassportReports',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthDiaryControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HealthDiary;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/health-diaries/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthDiaryControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: HealthDiaryPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/health-diaries/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HealthDiaryWithRelations HealthDiary model instance
   * @throws ApiError
   */
  public static healthDiaryControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<HealthDiaryWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static healthDiaryControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/health-diaries/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns HealthDiary HealthDiary model instance
   * @throws ApiError
   */
  public static healthDiaryControllerCreate({
    requestBody,
  }: {
    requestBody?: NewHealthDiary;
  }): CancelablePromise<HealthDiary> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/health-diaries',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any HealthDiary PATCH success count
   * @throws ApiError
   */
  public static healthDiaryControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: HealthDiaryPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/health-diaries',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns HealthDiaryWithRelations Array of HealthDiary model instances
   * @throws ApiError
   */
  public static healthDiaryControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<HealthDiaryWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/health-diaries',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any[] Array of HealthDiary model instances
   * @throws ApiError
   */
  public static healthDiaryControllerHealthDiaryBloodPressureChart({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthDiaryBloodPressureChart/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any[] Array of HealthDiary model instances
   * @throws ApiError
   */
  public static healthDiaryControllerHealthDiaryExerciseChart({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthDiaryExerciseChart/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any[] Array of HealthDiary model instances
   * @throws ApiError
   */
  public static healthDiaryControllerHealthDiaryWaistChart({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthDiaryWaistChart/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any[] Array of HealthDiary model instances
   * @throws ApiError
   */
  public static healthDiaryControllerHealthDiaryWeightChart({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/healthDiaryWeightChart',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any User Health Metrics
   * @throws ApiError
   */
  public static healthDiaryControllerGetUserHealthDiaryByUserId({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<{
    bmi?: Bmi;
    prostrate?: Psa;
    weight?: Weight;
    bloodSugar?: Hbaic;
    exercise?: Exercise;
    glucose?: BloodSugar;
    cholesterol?: Cholestrol;
    waist?: WaistCircumference;
    pregnancy?: PregnancyCycle;
    bloodPressure?: BloodPressure;
    medication?: MedicationPassport;
    eyeExamination?: EyeExamination;
    footExamination?: FootExamination;
    temperature?: Temperature;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/userHealthDiaryByUserId/{userId}',
      path: {
        userId: userId,
      },
    });
  }
}
