import { FC, ReactElement, useEffect } from 'react';
import { useCountdown } from 'utils/useCountdown';

export type CountdownChildrenProps = {
  currentCountdown: number;
  isComplete: boolean;
  restart: (newDuration?: number) => void;
  start: (newDuration?: number) => void;
  stop: () => void;
};

type CountdownProps = {
  duration: number;
  callbackOnComplete?: () => void;
  shouldStop?: boolean;
  children: (params: CountdownChildrenProps) => ReactElement;
  shouldStartOnMount?: boolean;
};

const Countdown: FC<CountdownProps> = (props) => {
  const { duration, shouldStop, callbackOnComplete, children, shouldStartOnMount = true } = props;
  const {
    countdown: currentCountdown,
    isComplete,
    restart,
    stop,
    start,
  } = useCountdown({
    duration,
  });

  useEffect(() => {
    if (shouldStartOnMount) {
      start();
    } else {
      stop();
    }
  }, [start, shouldStartOnMount, stop]);

  useEffect(() => {
    if (isComplete) {
      callbackOnComplete?.();
    }
  }, [callbackOnComplete, isComplete]);

  useEffect(() => {
    if (shouldStop) {
      stop();
    }
  }, [shouldStop, stop]);

  return children({ currentCountdown, isComplete, restart, start, stop });
};

export { Countdown };
