import { useCallback, useEffect, useRef, useState } from 'react';

type UseCountdownProps = {
  duration: number;
  speed?: number;
};

/**
 * Hook to simulate a countdown
 */
const useCountdown = (props: UseCountdownProps) => {
  const { duration, speed = 1 } = props;

  const [countdown, setCountdown] = useState(duration);

  const [isComplete, setIsComplete] = useState(false);

  const intervalId = useRef<NodeJS.Timeout | undefined>();

  const currentCountdown = useRef(countdown);

  const initialize = useCallback((initializeDuration: number) => {
    setIsComplete(false);
    setCountdown(initializeDuration);
  }, []);

  const tick = useCallback(() => {
    setCountdown((previous) => previous - 1);
  }, []);

  const stop = useCallback(() => {
    setIsComplete(true);
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  }, []);

  const start = useCallback(
    (newDuration = currentCountdown.current) => {
      stop();
      initialize(newDuration);
      intervalId.current = setInterval(() => {
        tick();
      }, speed * 1000);
    },
    [initialize, speed, stop, tick],
  );

  const restart = useCallback(
    (newDuration = duration) => {
      start(newDuration);
    },
    [duration, start],
  );

  useEffect(() => {
    currentCountdown.current = countdown;
    if (countdown <= 0) {
      stop();
    }
  }, [countdown, stop]);

  return {
    countdown,
    intervalId,
    isComplete,
    restart,
    start,
    stop,
  };
};

export { useCountdown };
