import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { Outlet, useOutletContext } from 'react-router-dom';
import useDisclosure from 'utils/useDisclosure/useDisclosure';

interface IOutletContext {
  toggle: () => void;
}

const DashboardComponent = () => {
  const props = useDisclosure();
  return (
    <DashboardLayout {...props}>
      <Outlet
        context={{
          toggle: props.toggle,
        }}
      />
    </DashboardLayout>
  );
};

export function useDashboardComponent() {
  return useOutletContext<IOutletContext>();
}

export default DashboardComponent;
