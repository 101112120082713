import { apiWrapper, httpClient } from 'api';
import localforage from 'localforage';
import { useCallback } from 'react';
import useLoading from 'utils/useLoading/useLoading';

const useUploadImage = ({ endpoint }: { endpoint?: string }) => {
  const { loading, startLoading, stopLoading } = useLoading();

  const uploadImage = useCallback(
    async (payload) => {
      startLoading();
      try {
        const accessToken = await localforage.getItem('accessToken');
        const res = await apiWrapper(() =>
          httpClient.post(endpoint || `/payments/upload`, payload, {
            headers: {
              'Content-type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        );
        stopLoading();
        return res;
      } catch (error) {
        stopLoading();
      }
    },
    [startLoading, stopLoading, endpoint],
  );

  return {
    loading,
    uploadImage,
  };
};

export default useUploadImage;
