import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

type PropType = {
  height?: number;
  value?: string;
  onEditorChange: (val: any) => void;
};

const TextEditor = ({ height = 200, onEditorChange, value }: PropType) => {
  const editorRef = useRef(null);
  return (
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      value={value}
      onEditorChange={(content) => {
        onEditorChange(content);
      }}
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      init={{
        height: height,
        menubar: true,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'fullscreen',
          'insertdatetime',
          'help',
        ],
        branding: false,
        promotion: false,
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        a11y_advanced_options: true,
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
};
export default TextEditor;
