/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewProvider } from '../models/NewProvider';
import type { Provider } from '../models/Provider';
import type { ProviderPartial } from '../models/ProviderPartial';
import type { ProviderWithRelations } from '../models/ProviderWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProviderControllerService {
  /**
   * @returns any Provider model count
   * @throws ApiError
   */
  public static providerControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/providers/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any Providers  success
   * @throws ApiError
   */
  public static providerControllerDashboardMyProviders(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/providers/dashboardMyProviders',
    });
  }

  /**
   * @returns any Providers  success
   * @throws ApiError
   */
  public static providerControllerSearchProvider({
    name,
    address,
  }: {
    name?: string;
    address?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/providers/searchProvider',
      query: {
        name: name,
        address: address,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static providerControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Provider;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/providers/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static providerControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ProviderPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/providers/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProviderWithRelations Provider model instance
   * @throws ApiError
   */
  public static providerControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ProviderWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/providers/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static providerControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/providers/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Provider Provider model instance
   * @throws ApiError
   */
  public static providerControllerCreate({
    requestBody,
  }: {
    requestBody?: NewProvider;
  }): CancelablePromise<Provider> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/providers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Provider PATCH success count
   * @throws ApiError
   */
  public static providerControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: ProviderPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/providers',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProviderWithRelations Array of Provider model instances
   * @throws ApiError
   */
  public static providerControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ProviderWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/providers',
      query: {
        filter: filter,
      },
    });
  }
}
