import { useEffect, useRef } from 'react';
import useDisclosure from 'utils/useDisclosure/useDisclosure';

export interface IListItemProps {
  iconClass?: string;
  title: string;
  Icon?: any;
  onClick?: () => void;
  closeMenu?: () => void;
  theme?: string;
}

const ListItem = ({ iconClass, title, onClick, Icon, closeMenu, theme }: IListItemProps) => {
  return (
    <div
      onClick={() => {
        onClick?.();
        closeMenu?.();
      }}
      className="px-4 py-[10px] border-b last:border-b-0 border-gray-100 hover:bg-offwhite flex items-center space-x-2 cursor-pointer"
    >
      <i style={{ color: '#666' }} className={iconClass || '' + theme || 'text-gray-400'}>
        {Icon}
      </i>
      <p className="text-xs text-gray-500">{title}</p>
    </div>
  );
};

interface IMenuProps {
  itemList: IListItemProps[];
  isOpen: boolean;
  closeMenu: () => void;
}

const Menu = ({ itemList, isOpen, closeMenu }: IMenuProps) => {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        display: isOpen ? 'block' : 'none',
        zIndex: 30,
      }}
      className="w-44 absolute top-[105%] right-0"
    >
      <div className="w-full flex justify-end pr-2">
        <div className="w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent" />
      </div>
      <div className="bg-white rounded-md shadow-lg">
        {itemList.map((item, index) => (
          <ListItem
            key={index}
            iconClass={item.iconClass}
            Icon={item.Icon}
            title={item.title}
            onClick={item.onClick}
            closeMenu={closeMenu}
          />
        ))}
      </div>
    </div>
  );
};

const useMenu = () => {
  const { isOpen, onOpen, onClose, toggle } = useDisclosure();

  return {
    Menu: ({ itemList }: Omit<IMenuProps, 'isOpen' | 'closeMenu'>) => (
      <Menu itemList={itemList} isOpen={isOpen} closeMenu={onClose} />
    ),
    isMenuOpen: isOpen,
    onOpenMenu: onOpen,
    onCloseMenu: onClose,
    toggleMenu: toggle,
  };
};

export default useMenu;
