/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BloodSugar } from '../models/BloodSugar';
import type { BloodSugarPartialExcluding_id_defaultUnit_defaultAfterMeal_defaultBeforeMeal_modified_at_ } from '../models/BloodSugarPartialExcluding_id_defaultUnit_defaultAfterMeal_defaultBeforeMeal_modified_at_';
import type { BloodSugarWithRelations } from '../models/BloodSugarWithRelations';
import type { NewBloodSugar } from '../models/NewBloodSugar';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BloodSugarControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static bloodSugarControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: BloodSugarPartialExcluding_id_defaultUnit_defaultAfterMeal_defaultBeforeMeal_modified_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/blood-sugar/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BloodSugarWithRelations BloodSugar model instance
   * @throws ApiError
   */
  public static bloodSugarControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BloodSugarWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blood-sugar/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static bloodSugarControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/blood-sugar/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns BloodSugar BloodSugar model instance
   * @throws ApiError
   */
  public static bloodSugarControllerCreate({
    requestBody,
  }: {
    requestBody?: NewBloodSugar;
  }): CancelablePromise<BloodSugar> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/blood-sugar',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BloodSugarWithRelations Array of BloodSugar model instances
   * @throws ApiError
   */
  public static bloodSugarControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<BloodSugarWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blood-sugars',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
