import { Notification } from 'generated';
import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import NotificationActionTypes from './types';

export type INotificationAppState = {
  error?: string;
  unreadNotifications?: Notification[];
  unreadNotificationCount?: number;
};

export const INITIAL_NOTIFICATION_STATE: INotificationAppState = {
  error: '',
  unreadNotificationCount: 0,
  unreadNotifications: [],
};

function notificationReducer(
  state: INotificationAppState = INITIAL_NOTIFICATION_STATE,
  { type, payload },
) {
  switch (type) {
    case NotificationActionTypes.UPDATE_APP_NOTIFICATION_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'notification',
  storage,
  blacklist: ['error', 'isLoading', 'loading'],
};

export default persistReducer(persistConfig, notificationReducer);
