/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Chat } from '../models/Chat';
import type { ChatPartial } from '../models/ChatPartial';
import type { ChatWithRelations } from '../models/ChatWithRelations';
import type { NewChat } from '../models/NewChat';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatControllerService {
  /**
   * @returns Chat Get unread messages
   * @throws ApiError
   */
  public static chatControllerGetUnreadMessages({
    id,
    senderType,
  }: {
    id: string;
    senderType?: string;
  }): CancelablePromise<Chat> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chats/unread/{id}',
      path: {
        id: id,
      },
      query: {
        senderType: senderType,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static chatControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ChatPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/chats/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ChatWithRelations Chat model instance
   * @throws ApiError
   */
  public static chatControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ChatWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chats/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns Chat Chat model instance
   * @throws ApiError
   */
  public static chatControllerCreate({
    requestBody,
  }: {
    requestBody?: NewChat;
  }): CancelablePromise<Chat> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/chats',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Chat model instances
   * @throws ApiError
   */
  public static chatControllerFind({
    filter,
    page,
    reverse,
    chatRoomUsers,
  }: {
    filter?: any;
    page?: number;
    reverse?: boolean;
    chatRoomUsers?: any;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<ChatWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chats',
      query: {
        filter: filter,
        page: page,
        reverse: reverse,
        chatRoomUsers: chatRoomUsers,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static chatControllerUploadFile({
    formData,
  }: {
    /**
     * multipart/form-data value.
     */
    formData: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/chats-upload-file',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
}
