/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewWaistCircumference } from '../models/NewWaistCircumference';
import type { WaistCircumference } from '../models/WaistCircumference';
import type { WaistCircumferencePartialExcluding_id_defaultValue_defaultUnit_ } from '../models/WaistCircumferencePartialExcluding_id_defaultValue_defaultUnit_';
import type { WaistCircumferenceWithRelations } from '../models/WaistCircumferenceWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WaistCircumferenceControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static waistCircumferenceControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: WaistCircumferencePartialExcluding_id_defaultValue_defaultUnit_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/waist-circumference/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WaistCircumferenceWithRelations WaistCircumference model instance
   * @throws ApiError
   */
  public static waistCircumferenceControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<WaistCircumferenceWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/waist-circumference/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static waistCircumferenceControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/waist-circumference/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns WaistCircumference WaistCircumference model instance
   * @throws ApiError
   */
  public static waistCircumferenceControllerCreate({
    requestBody,
  }: {
    requestBody?: NewWaistCircumference;
  }): CancelablePromise<WaistCircumference> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/waist-circumference',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WaistCircumferenceWithRelations Array of WaistCircumference model instances
   * @throws ApiError
   */
  public static waistCircumferenceControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<WaistCircumferenceWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/waist-circumference',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
