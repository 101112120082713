import { apiWrapper } from 'api';
import { CoachControllerService } from 'generated';
import { NotificationManager } from 'react-notifications';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { handleError } from 'utils/handleError';
import useLoading from 'utils/useLoading/useLoading';

const useProfileUpdate = () => {
  const { loading, startLoading, stopLoading } = useLoading();
  const coach = useAppSelector((state) => state.user.currentUser);

  const updateProfile = async (requestBody, callback?: () => void) => {
    startLoading();
    try {
      const data = await apiWrapper(() =>
        CoachControllerService.coachControllerUpdateById({
          id: coach?.id,
          requestBody,
        }),
      );
      callback?.();
      NotificationManager.success('Profile updated.');
      stopLoading();
    } catch (error) {
      stopLoading();
      handleError(error);
    }
  };

  return {
    loading,
    updateProfile,
  };
};

export default useProfileUpdate;
