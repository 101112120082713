/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Glitches } from '../models/Glitches';
import type { GlitchesPartialExcluding_id_ } from '../models/GlitchesPartialExcluding_id_';
import type { GlitchesWithRelations } from '../models/GlitchesWithRelations';
import type { NewGlitches } from '../models/NewGlitches';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsultGlitchesControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static consultGlitchesControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: GlitchesPartialExcluding_id_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/consult-glitches/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns GlitchesWithRelations ConsultGlitches model instance
   * @throws ApiError
   */
  public static consultGlitchesControllerFindById({
    id,
  }: {
    id: string;
  }): CancelablePromise<GlitchesWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/consult-glitches/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static consultGlitchesControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/consult-glitches/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Glitches ConsultGlitches model instance
   * @throws ApiError
   */
  public static consultGlitchesControllerCreate({
    requestBody,
  }: {
    requestBody?: NewGlitches;
  }): CancelablePromise<Glitches> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/consult-glitches',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of ConsultGlitches model instances
   * @throws ApiError
   */
  public static consultGlitchesControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<GlitchesWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/consult-glitches',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
