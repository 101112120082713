/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewOption } from '../models/NewOption';
import type { NewQuestion } from '../models/NewQuestion';
import type { Option } from '../models/Option';
import type { OptionPartial } from '../models/OptionPartial';
import type { Question } from '../models/Question';
import type { QuestionPartial } from '../models/QuestionPartial';
import type { QuestionWithRelations } from '../models/QuestionWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionControllerService {
  /**
   * @returns Question Question model instance
   * @throws ApiError
   */
  public static questionControllerCreateQuestionBulk({
    requestBody,
  }: {
    requestBody?: Array<{
      question?: NewQuestion;
      options?: Array<OptionPartial>;
    }>;
  }): CancelablePromise<Question> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/question/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerUpdateOptionBulk({
    requestBody,
  }: {
    requestBody?: Array<OptionPartial>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/question/bulk-option',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerUpdateQuestionBulk({
    requestBody,
  }: {
    requestBody?: Array<{
      id?: string;
      order?: number;
    }>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/question/bulk-questions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerUpdateOption({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: OptionPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/question/option/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerDeleteOption({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/question/option/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Option Option model instance
   * @throws ApiError
   */
  public static questionControllerCreateOption({
    requestBody,
  }: {
    requestBody?: NewOption;
  }): CancelablePromise<Option> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/question/option',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerUpdateQuestion({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: {
      question?: QuestionPartial;
      options?: any[];
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/question/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static questionControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/question/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Question Question model instance
   * @throws ApiError
   */
  public static questionControllerCreate({
    requestBody,
  }: {
    requestBody?: {
      question?: NewQuestion;
      options?: any[];
    };
  }): CancelablePromise<Question> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/question',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns QuestionWithRelations Array of question model instances
   * @throws ApiError
   */
  public static questionControllerFindAll({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<QuestionWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/question',
      query: {
        filter: filter,
      },
    });
  }
}
