import { SVGProps } from 'react';

export const FishIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none" {...props}>
    <path
      fill="#021B33"
      d="M21 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm3.943 11.346c-3.382 3.375-8.677 4.794-15.79 4.244.2 1.487.479 3.052.838 4.696a1 1 0 0 1-1.954.428 58.058 58.058 0 0 1-.924-5.33 58.524 58.524 0 0 1-5.326-.924 1 1 0 0 1 .428-1.954 59 59 0 0 0 4.692.838c-.549-7.111.875-12.405 4.25-15.787 5.469-5.485 14.148-3.945 15.831-3.58a2.009 2.009 0 0 1 1.535 1.535c.365 1.684 1.907 10.363-3.58 15.834Zm-1.875-.986A13.016 13.016 0 0 1 12.142 9.426C9.446 12.47 8.376 17.21 8.928 23.57c6.362.556 11.1-.514 14.144-3.209h-.004Zm3.5-14.424a20.98 20.98 0 0 0-6.156-.347c-2.53.25-4.675.978-6.406 2.182A11 11 0 0 0 24.73 18.5c1.202-1.731 1.936-3.875 2.182-6.406a21.126 21.126 0 0 0-.344-6.158Z"
    />
  </svg>
);

export const DotIcon = ({ fill }: { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
    <circle cx="3" cy="3" r="3" fill={fill || '#3C91E6'} />
  </svg>
);
export const FatSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M7.66634 14.4997C11.1641 14.4997 13.9997 11.6641 13.9997 8.16634C13.9997 4.66854 11.1641 1.83301 7.66634 1.83301C4.16854 1.83301 1.33301 4.66854 1.33301 8.16634C1.33301 11.6641 4.16854 14.4997 7.66634 14.4997Z"
      stroke="#9A9EA6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 15.1663L13.333 13.833"
      stroke="#9A9EA6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageDeliveredIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33331 8.66663L3.68426 10.5474C4.2358 10.9886 5.03575 10.9232 5.50825 10.3982L10.6666 4.66663M5.33331 8.66663L7.68426 10.5474C8.2358 10.9886 9.03575 10.9232 9.50825 10.3982L14.6666 4.66663"
      stroke={props.color || '#8C8C8C'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageSentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" {...props}>
    <g id="checkmark">
      <path
        d="m3 16 9 9M12 25 29 7"
        stroke={props.color || '#8C8C8C'}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.5"
        className="cls-1"
      />
    </g>
  </svg>
);
export const FolderIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="83" height="64" viewBox="0 0 83 64" {...props}>
    <g clipPath="url(#clip0_519_15912)">
      <path
        d="M4.99998 63.4028H77.123C79.8845 63.4028 82.123 61.1642 82.123 58.4028V10.0187C82.123 7.25724 79.8845 5.01866 77.1231 5.01866H41.6116C40.0132 5.01866 38.5397 4.15442 37.7596 2.75933C36.9794 1.36424 35.5059 0.5 33.9075 0.5H4.99998C2.23856 0.5 -2.28882e-05 2.73857 -2.28882e-05 5.5V58.4028C-2.28882e-05 61.1642 2.23856 63.4028 4.99998 63.4028Z"
        fill="#E6B800"
      />
      <rect
        fill="black"
        fillOpacity="0.05"
        height="53.0014"
        rx="10"
        width="73.3866"
        x="3.49414"
        y="7.29492"
      />
      <rect fill="white" height="53.0014" rx="5" width="73.3866" x="4.27148" y="8.26562" />
      <path
        d="M72.1231 62.8209H10C4.47715 62.8209 0 58.3438 0 52.8209V26.7009C0 21.1781 4.47714 16.7009 9.99999 16.7009H40.1232C42.0229 16.7009 43.7437 15.5802 44.5117 13.8427C45.2797 12.1052 47.0005 10.9844 48.9002 10.9844H72.1231C77.6459 10.9844 82.1231 15.4615 82.1231 20.9844V52.8209C82.1231 58.3438 77.6459 62.8209 72.1231 62.8209Z"
        fill="black"
        fillOpacity="0.06"
      />
      <path
        d="M77.1231 63.403H5C2.23858 63.403 0 61.1644 0 58.403V22.283C0 19.5215 2.23857 17.283 4.99999 17.283H40.1232C42.0229 17.283 43.7437 16.1622 44.5117 14.4247C45.2797 12.6872 47.0005 11.5664 48.9002 11.5664H77.1231C79.8845 11.5664 82.1231 13.805 82.1231 16.5664V58.403C82.1231 61.1644 79.8845 63.403 77.1231 63.403Z"
        fill="#FFCC00"
      />
      <path
        clipRule="evenodd"
        d="M33.0703 63.5006H77.0267C79.7881 63.5006 82.0267 61.262 82.0267 58.5006V35.9102C68.2098 50.5296 49.2851 59.0149 33.0703 63.5006Z"
        fill="#EBBE0B"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_519_15912">
        <rect fill="white" height="63.0003" transform="translate(0 0.5)" width="82.1233" />
      </clipPath>
    </defs>
  </svg>
);
