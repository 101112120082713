/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coach } from '../models/Coach';
import type { Consult } from '../models/Consult';
import type { ConsultPartialExcluding_id_ } from '../models/ConsultPartialExcluding_id_';
import type { ConsultWithRelations } from '../models/ConsultWithRelations';
import type { NewConsult } from '../models/NewConsult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsultControllerService {
  /**
   * @returns any Statistics for a coach consult
   * @throws ApiError
   */
  public static consultControllerGetStatistics({
    partnerId,
  }: {
    partnerId: string;
  }): CancelablePromise<{
    totalConsults?: number;
    totalBooked?: number;
    totalAccepted?: number;
    totalCompleted?: number;
    highPriority?: number;
    mediumPriority?: number;
    lowPriority?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/consult/statistics/{partnerId}',
      path: {
        partnerId: partnerId,
      },
    });
  }

  /**
   * @returns any Statistics for a coach consult
   * @throws ApiError
   */
  public static consultControllerVerifyConsultCode({
    partnerId,
    consultId,
    requestBody,
  }: {
    partnerId?: string;
    consultId?: string;
    requestBody?: {
      passcode?: string;
    };
  }): CancelablePromise<{
    consult?: ConsultWithRelations;
    accessToken?: string;
    refreshToken?: string;
    coach?: Coach;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/consult/verify',
      query: {
        partnerId: partnerId,
        consultId: consultId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static consultControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ConsultPartialExcluding_id_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/consult/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ConsultWithRelations Consult model instance
   * @throws ApiError
   */
  public static consultControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ConsultWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/consult/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static consultControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/consult/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Consult Consult model instance
   * @throws ApiError
   */
  public static consultControllerCreate({
    requestBody,
  }: {
    requestBody?: NewConsult;
  }): CancelablePromise<Consult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/consult',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Consult model instances
   * @throws ApiError
   */
  public static consultControllerFindAll({
    filter,
    page,
    search,
  }: {
    filter?: any;
    page?: number;
    search?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<ConsultWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/consult',
      query: {
        filter: filter,
        page: page,
        search: search,
      },
    });
  }
}
