/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoalAction } from '../models/GoalAction';
import type { GoalActionPartialExcluding_id_created_at_ } from '../models/GoalActionPartialExcluding_id_created_at_';
import type { GoalActionWithRelations } from '../models/GoalActionWithRelations';
import type { NewGoalAction } from '../models/NewGoalAction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GoalActionControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static goalActionControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: GoalActionPartialExcluding_id_created_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/goal-actions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns GoalActionWithRelations GoalAction model instance
   * @throws ApiError
   */
  public static goalActionControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<GoalActionWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/goal-actions/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static goalActionControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/goal-actions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns GoalAction GoalAction model instance
   * @throws ApiError
   */
  public static goalActionControllerCreate({
    requestBody,
  }: {
    requestBody?: NewGoalAction;
  }): CancelablePromise<GoalAction> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/goal-actions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of GoalAction model instances
   * @throws ApiError
   */
  public static goalActionControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<GoalActionWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/goal-actions',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
