/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewProcedureSurgeries } from '../models/NewProcedureSurgeries';
import type { ProcedureSurgeries } from '../models/ProcedureSurgeries';
import type { ProcedureSurgeriesPartial } from '../models/ProcedureSurgeriesPartial';
import type { ProcedureSurgeriesWithRelations } from '../models/ProcedureSurgeriesWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProcedureSurgeriesControllerService {
  /**
   * @returns any ProcedureSurgeries model count
   * @throws ApiError
   */
  public static procedureSurgeriesControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/procedure-surgeries/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static procedureSurgeriesControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ProcedureSurgeries;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/procedure-surgeries/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static procedureSurgeriesControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ProcedureSurgeriesPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/procedure-surgeries/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProcedureSurgeriesWithRelations ProcedureSurgeries model instance
   * @throws ApiError
   */
  public static procedureSurgeriesControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ProcedureSurgeriesWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/procedure-surgeries/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static procedureSurgeriesControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/procedure-surgeries/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ProcedureSurgeries ProcedureSurgeries model instance
   * @throws ApiError
   */
  public static procedureSurgeriesControllerCreate({
    requestBody,
  }: {
    requestBody?: NewProcedureSurgeries;
  }): CancelablePromise<ProcedureSurgeries> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/procedure-surgeries',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any ProcedureSurgeries PATCH success count
   * @throws ApiError
   */
  public static procedureSurgeriesControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: ProcedureSurgeriesPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/procedure-surgeries',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProcedureSurgeriesWithRelations Array of ProcedureSurgeries model instances
   * @throws ApiError
   */
  public static procedureSurgeriesControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ProcedureSurgeriesWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/procedure-surgeries',
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any ProcedureSurgeries Migration instance
   * @throws ApiError
   */
  public static procedureSurgeriesControllerProcedureSurgeriesMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/procedureSurgeriesMigration',
    });
  }
}
