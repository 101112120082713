/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoachType } from '../models/CoachType';
import type { CoachTypePartial } from '../models/CoachTypePartial';
import type { CoachTypeWithRelations } from '../models/CoachTypeWithRelations';
import type { NewCoachType } from '../models/NewCoachType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoachTypeControllerService {
  /**
   * @returns any CoachType model count
   * @throws ApiError
   */
  public static coachTypeControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coach-types/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachTypeControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CoachType;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/coach-types/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachTypeControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CoachTypePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coach-types/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachTypeWithRelations CoachType model instance
   * @throws ApiError
   */
  public static coachTypeControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CoachTypeWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coach-types/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachTypeControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/coach-types/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns CoachType CoachType model instance
   * @throws ApiError
   */
  public static coachTypeControllerCreate({
    requestBody,
  }: {
    requestBody?: NewCoachType;
  }): CancelablePromise<CoachType> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coach-types',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any CoachType PATCH success count
   * @throws ApiError
   */
  public static coachTypeControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: CoachTypePartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coach-types',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachTypeWithRelations Array of CoachType model instances
   * @throws ApiError
   */
  public static coachTypeControllerGetAllCoachTypes(): CancelablePromise<
    Array<CoachTypeWithRelations>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coach-types',
    });
  }
}
