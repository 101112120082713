/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPregnancyLog } from '../models/NewPregnancyLog';
import type { PregnancyLog } from '../models/PregnancyLog';
import type { PregnancyLogPartial } from '../models/PregnancyLogPartial';
import type { PregnancyLogWithRelations } from '../models/PregnancyLogWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PregnancyLogControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static pregnancyLogControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PregnancyLogPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/pregnancy-log/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PregnancyLogWithRelations PregnancyLog model instance
   * @throws ApiError
   */
  public static pregnancyLogControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PregnancyLogWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pregnancy-log/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static pregnancyLogControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/pregnancy-log/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PregnancyLog PregnancyLog model instance
   * @throws ApiError
   */
  public static pregnancyLogControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPregnancyLog;
  }): CancelablePromise<PregnancyLog> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/pregnancy-log',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PregnancyLogWithRelations Array of PregnancyLog model instances
   * @throws ApiError
   */
  public static pregnancyLogControllerFindAll({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<PregnancyLogWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/pregnancy-log',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
