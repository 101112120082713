/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Note } from '../models/Note';
import type { NoteExcluding_id_ } from '../models/NoteExcluding_id_';
import type { NotePartial } from '../models/NotePartial';
import type { NoteWithRelations } from '../models/NoteWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotesControllerService {
  /**
   * @returns NoteWithRelations Array of Notes model instances
   * @throws ApiError
   */
  public static notesControllerGetByCoachId({
    coachId,
    filter,
    page,
  }: {
    coachId?: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<NoteWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notes/byCoachId',
      query: {
        coachId: coachId,
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns NoteWithRelations Array of Notes model instances
   * @throws ApiError
   */
  public static notesControllerGetByDependentId({
    dependentId,
  }: {
    dependentId?: string;
  }): CancelablePromise<Array<NoteWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notes/byDependentId',
      query: {
        dependentId: dependentId,
      },
    });
  }

  /**
   * @returns NoteWithRelations Array of Notes model instances
   * @throws ApiError
   */
  public static notesControllerGetByUserId({
    userId,
  }: {
    userId?: string;
  }): CancelablePromise<Array<NoteWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notes/byUserId',
      query: {
        userId: userId,
      },
    });
  }

  /**
   * @returns NoteWithRelations Array of Notes model instances
   * @throws ApiError
   */
  public static notesControllerGetByUserIdAndCoachId({
    userId,
    coachId,
  }: {
    userId?: string;
    coachId?: string;
  }): CancelablePromise<Array<NoteWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notes/byUserIdAndCoachId',
      query: {
        userId: userId,
        coachId: coachId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static notesControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: NotePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/notes/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static notesControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/notes/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Note Notes model instance
   * @throws ApiError
   */
  public static notesControllerCreate({
    requestBody,
  }: {
    requestBody?: NoteExcluding_id_;
  }): CancelablePromise<Note> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/notes',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
