/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Disease } from '../models/Disease';
import type { DiseasePartial } from '../models/DiseasePartial';
import type { DiseaseWithRelations } from '../models/DiseaseWithRelations';
import type { NewDisease } from '../models/NewDisease';
import type { NewUserDisease } from '../models/NewUserDisease';
import type { UserDisease } from '../models/UserDisease';
import type { UsersWithRelations } from '../models/UsersWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DiseaseControllerService {
  /**
   * @returns UserDisease UserDisease model instance
   * @throws ApiError
   */
  public static diseaseControllerAddUserToDisease({
    requestBody,
  }: {
    requestBody?: NewUserDisease;
  }): CancelablePromise<UserDisease> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/diseases/addUser',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Disease model count
   * @throws ApiError
   */
  public static diseaseControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/diseases/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns DiseaseWithRelations Coach model instance
   * @throws ApiError
   */
  public static diseaseControllerGetDiseasesList({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<DiseaseWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/diseases/getDiseasesList',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UsersWithRelations Array of Users model instances
   * @throws ApiError
   */
  public static diseaseControllerGetDiseaseUsers({
    id,
    filter,
    page,
  }: {
    id: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<UsersWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/diseases/users/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static diseaseControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Disease;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/diseases/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static diseaseControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: DiseasePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/diseases/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DiseaseWithRelations Disease model instance
   * @throws ApiError
   */
  public static diseaseControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<DiseaseWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/diseases/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static diseaseControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/diseases/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Disease Disease model instance
   * @throws ApiError
   */
  public static diseaseControllerCreate({
    requestBody,
  }: {
    requestBody?: NewDisease;
  }): CancelablePromise<Disease> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/diseases',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Disease PATCH success count
   * @throws ApiError
   */
  public static diseaseControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: DiseasePartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/diseases',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DiseaseWithRelations Array of Disease model instances
   * @throws ApiError
   */
  public static diseaseControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<DiseaseWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/diseases',
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns any Disease model instance
   * @throws ApiError
   */
  public static diseaseControllerDiseasesMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/diseasesMigration',
    });
  }
}
