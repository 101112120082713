import localforage from 'localforage';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { store } from 'redux/store';
import { logoutUser } from 'redux/user/actions';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 420 }: { onIdle: () => void; idleTime?: number }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const logout = async () => {
    await localforage.clear();
    store?.dispatch(logoutUser());
  };
  const handleIdle = () => {
    setIdle(true);
  };

  const promptTimeout = 90000;

  const idleTimer = useIdleTimer({
    timeout: idleTimeout - promptTimeout,
    promptTimeout,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
    logoutUser: logout,
  };
};
export default useIdleTimeout;
