import Button from 'components/Buttons/Button';
import ModalComponent from 'components/Modal/Modal';
import ModalFooter from 'components/Modal/ModalFooter';

interface IProps {
  title: string;
  description: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  icon?: any;
  buttonColor?: string;
  loading?: boolean;
  children?: React.ReactNode;
  isBtnDisabled?: boolean;
  buttonText?: string;
  hideClose?: boolean;
}

const ConfirmationModal = ({
  title,
  description,
  isOpen,
  onClose,
  onSubmit,
  icon,
  buttonColor,
  loading,
  children,
  isBtnDisabled,
  buttonText,
  hideClose = false,
}: IProps) => {
  return (
    <ModalComponent
      title={title || 'Please Confirm'}
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      hideClose={hideClose}
      disableBackdropClick={hideClose}
    >
      <section className="p-4">
        <div className="my-8 flex flex-col text-center gap-2 items-center">
          <div>{icon}</div>
          <div className="ml-4">
            <p className="text-gray-700">{description}</p>
          </div>
        </div>
        {children && <div className="mb-10">{children}</div>}
      </section>
      {onSubmit && (
        <ModalFooter>
          <div className="flex justify-end">
            {!hideClose && (
              <button
                onClick={onClose}
                className="text-sm border border-gray-600 rounded p-2 hover:bg-slate-100 mr-4 px-6"
                style={{
                  transition: '.2s ease-in-out',
                }}
              >
                Cancel
              </button>
            )}
            <Button
              disabled={isBtnDisabled}
              isLoading={loading}
              backgroundColor={buttonColor}
              onClick={onSubmit}
            >
              {buttonText || 'Yes, proceed'}
            </Button>
          </div>
        </ModalFooter>
      )}
    </ModalComponent>
  );
};

export default ConfirmationModal;
